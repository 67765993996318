import logo from "./logo.svg";
import "tailwindcss/tailwind.css";
import "./App.css";
import "./app/globals.css";
import RootLayout from "./app/[locale]/layout.tsx";
import Providers from "./app/[locale]/providers.tsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "./app/[locale]/page.tsx";
import AboutPage from "./app/[locale]/about/page.tsx";
import AccreditedSpecializationsPage from "./app/[locale]/accredited-specializations/page.tsx";
import AccreditedUniversitiesPage from "./app/[locale]/accredited-universities/page.tsx";
import ContactUsPage from "./app/[locale]/contact-us/page.tsx";
import HowToApplyPage from "./app/[locale]/how-to-apply/page.tsx";
import ServicesPage from "./app/[locale]/services/page.tsx";
import { handleSendMessage } from "../src/utils/ForwordToWhatsapp.ts";

import { IntlProvider } from "react-intl";
import messages_en from "./messages/en.json"; // Import your translation files
import messages_ar from "./messages/ar.json";
import { useLocale } from "./store/LocaleContext.js";
import { useEffect, useState } from "react";

console.log(messages_en);

const messages = {
  en: messages_en,
  ar: messages_ar,
};

function App() {
  const { locale } = useLocale();
  const language = locale;

  useEffect(() => {
    if (language === "en") {
      document.title = "Tasheel Platform";
    } else {
      document.title = "تسهيل";
    }
  }, []);

  return (
    <Router>
      <IntlProvider locale={language} messages={messages[language]}>
        <Providers>
          <RootLayout params={{ locale: language }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route
                path="/accredited-specializations"
                element={<AccreditedSpecializationsPage />}
              />
              <Route
                path="/accredited-universities"
                element={<AccreditedUniversitiesPage />}
              />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/how-to-apply" element={<HowToApplyPage />} />
              <Route path="/services" element={<ServicesPage />} />
            </Routes>
            <a
              className="floating_wp-button "
              target="_blank"
              href={handleSendMessage()}
            >
              <img
                src="/icons/global_whatsapp_btn.svg"
                alt="global_whatsapp_btn"
              />
            </a>
            <ToastContainer />
          </RootLayout>
        </Providers>
      </IntlProvider>
    </Router>
  );
}

export default App;

import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Checkbox ( { id, className, onChange, checked }: { id: string, className?: string, onChange?: () => void, checked?: boolean } )
{
    const newClassName = twMerge( 'form-checkbox cursor-pointer rounded focus:outline-none outline-none ring-transparent', className )
    return (
        <input checked={ checked } onChange={ onChange } type="checkbox" id={ id } className={ newClassName } />
        // <input type="checkbox" id={ id } className="checked:bg-slate-300" />

    )
}

import { fetchApi } from "../../../utils/fetchService.ts";

export const fetchUniversities = async ( locale: string, query?: string ) =>
{
    const url = {
        production : "/get_university",
        testing : "/get_page_wise_university"
    }
    const response = await fetchApi( url.testing, {
        headers: {
            "x-api-key": "1234"
        }
    }, locale, query );
    return response;
}


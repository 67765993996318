import Divider from '../../../shared/components/Divider.tsx'
import React from 'react'
import { useIntl } from 'react-intl'
export default function Cards ()
{
    const intl = useIntl();
    const { locale } = intl;
    const t = useIntl().messages.services

    const cards = [
        {
            id: 1,
            title: t.tips.firstTip.title,
            description: t.tips.firstTip.description,
            description2: t.tips.firstTip.description2,
        },
        {
            id: 2,
            title: t.tips.secondTip.title,
            description: t.tips.secondTip.description,
        },
        {
            id: 3,
            title: t.tips.thirdTip.title,
            description: t.tips.thirdTip.description,
        },
        {
            id: 4,
            title: t.tips.fourthTip.title,
            description: t.tips.fourthTip.description,
            img: "/images/how-to-apply/sixth-img.jpg"
        }
    ]
    return (
        <section className='flex flex-col gap-14 mt-10 mb-10' >
            <section className='w-full flex items-center justify-start' >
                <article className='flex items-center gap-10 justify-center shadow-[10px_10px_10px_0px_#E538171C] border border-[#E53817] rounded-[32px] p-10 max-w-[871px] w-full max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 max-[430px]:flex-row' >
                    <div className='text-[#E53817] text-8xl font-normal basis-[10%] max-[700px]:basis-[5%] w-full  flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl' >{ cards[ 0 ]?.id }</div>
                    <Divider className='w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9] ' />
                    <div className='flex flex-col gap-5 basis-[85%]' >
                        <h2 className='text-[#E53817] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm' >{ cards[ 0 ]?.title }</h2>
                        <p className='text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs' >{ cards[ 0 ]?.description } <br /> { cards[ 0 ]?.description2 }</p>
                    </div>
                </article>
            </section>

            <section className='w-full flex items-center justify-end' >
                <article className='flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#3184811C] border border-[#318481] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row' >
                    <div className='text-[#318481] text-8xl font-normal basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl' >{ cards[ 1 ]?.id }</div>
                    <Divider className='w-[4px] min-h-[189px]  max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]' />
                    <div className='flex flex-col gap-5 basis-[85%]' >
                        <h2 className='text-[#318481] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm' >{ cards[ 1 ]?.title }</h2>
                        <p className='text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs' >{ cards[ 1 ]?.description }</p>
                    </div>
                </article>
            </section>

            <section className='w-full flex items-center justify-start' >
                <article className='flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#ED8F101C] border border-[#ED8F10] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row' >
                    <div className='text-[#ED8F10] text-8xl font-normal basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl' >{ cards[ 2 ]?.id }</div>
                    <Divider className='w-[4px] min-h-[189px]  max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]' />
                    <div className='flex flex-col gap-5 basis-[85%]' >
                        <h2 className='text-[#ED8F10] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm' >{ cards[ 2 ]?.title }</h2>
                        <p className='text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs' >{ cards[ 2 ]?.description }</p>
                    </div>
                </article>
            </section>

            <section className='w-full flex items-center justify-end' >
                <article className='relative flex items-center gap-5 justify-center shadow-[10px_10px_10px_0px_#85232D1C] border border-[#85232D] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-5 w-full max-[430px]:flex-row' >
                    <div className='text-[#85232D] text-8xl font-normal basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl' >{ cards[ 3 ]?.id }</div>
                    <img src={ cards[ 3 ]?.img || "/images/how-to-apply/sixth-img.jpg" } height={ 86 } width={ 142.19 } alt={ t.tips.thirdTip.title } className={`absolute bottom-3  max-[960px]:scale-[0.7] max-[700px]:h-[50px] max-[700px]:w-auto  ${
                        locale === "ar"
                            ? "right-3 max-[700px]:-right-5  max-[430px]:right-0 top=3"
                            : "left-3 max-[700px]:-left-5 max-[430px]:left-0 bottom-3"
                        }`} />
                    <Divider className='w-[4px] min-h-[189px]  max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]' />
                    <div className='flex flex-col gap-5 basis-[85%] max-[430px]:pb-3' >
                        <h2 className='text-[#85232D] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm' >{ cards[ 3 ]?.title }</h2>
                        <p className='text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs' >{ cards[ 3 ]?.description }</p>
                    </div>
                </article>
            </section>
        </section>
    )
}

import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppContext } from '../../../app/[locale]/providers.tsx';
import MultiChoiceSlider from '../../../shared/components/MultiChoiceSlider.tsx';
import { fetchDegrees } from '../api/fetchDegrees.ts';

import { useQuery } from '@tanstack/react-query';
import { useWindowSize } from 'usehooks-ts';

export default function DegreesMediaWrapper({ tDegrees, checkTr }: { tDegrees: string, checkTr: string }) {
    const intl = useIntl();
    const locale = intl.locale;
    const { degree_id, setDegreeId, country_id, specialization_id, specific_specialization_id } = useContext(AppContext);
    const { width } = useWindowSize();
    const [allChecked, setAllChecked] = useState(false);

    const getNumberOfSlides = () => {
        if (width > 1024) return 4;
        if (width > 900) return 3;
        if (width > 600) return 2;
        return 1;
    };

    const { degreeSearch, setDegreeSearch } = useContext(AppContext)

    const { data: degrees }: {
        data: ArrayResponseType<{
            id: string,
            academic_degree_name: string,
        }> | undefined
    } = useQuery({
        queryKey: [`degrees`, country_id, locale, degreeSearch],
        queryFn: () => fetchDegrees(locale, `&country_id=${country_id.length > 0 ? country_id.join(",") : ""}&degree_id=${degree_id.length > 0 ? degree_id.join(",") : ""}&specialization_id=${specialization_id.length > 0 ? specialization_id.join(",") : ""}&subspecialization_id=${specific_specialization_id.length > 0 ? specific_specialization_id.join(",") : ""}&search=${degreeSearch}`)
    });


    const choices = useMemo(() => {
        return degrees?.degree?.map(degree => ({
            id: degree?.id,
            name: degree?.academic_degree_name
        }));
    }, [degrees?.degree, locale, country_id, degree_id, specialization_id, specific_specialization_id,]);

    return (
        <MultiChoiceSlider
            setAllChecked={setAllChecked}
            allChecked={allChecked}
            search={degreeSearch}
            setSearch={setDegreeSearch}
            choicesChecked={degree_id}
            setChoicesChecked={setDegreeId}
            checkboxId='degrees'
            checkTr={checkTr}
            choices={choices}
            slidesPerView={getNumberOfSlides()}
            title={tDegrees}
            sliderClassName={`gap-4 max-[700px]:w-[100%] max-[600px]:w-[100%] max-[600px]:gap-2 max-[370px]:gap-1 max-[360px]:gap-5 max-[360px]:w-[100%] ${locale === 'ar' ? "max-[600px]:pr-2" : "max-[600px]:pl-2"}`}
            wrapperClassName='max-[360px]:flex-col max-[360px]:gap-10 items-start'
        />
    );
}

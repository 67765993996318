import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../app/[locale]/providers.tsx';


export default function CustomSearch({setSearch, search, searchMobileCSS}) {
    const intl = useIntl();
    const placeholder = intl.messages.common.search
    const [timeout, setTimeoutTimer] = useState();
    const [text, setText] = useState("");

    

    const handleSearch = (value) => {
        clearTimeout(timeout);
        const newTimer = setTimeout(() => {
            setSearch(value)
        }, 600);
        setTimeoutTimer(newTimer);
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setText(value);
        handleSearch(value);
    };

    return (
        <div className={`relative flex items-center rounded-xl py-1 max-w-[236px] h-full border border-[#d0d5dd] ${searchMobileCSS}`}>
            <input
                type="text"
                placeholder={placeholder}
                value={text}
                onChange={(e) => handleInputChange(e)}
                className={`text-[#667085] focus:outline-none focus:ring-transparent font-semibold w-full outline-none border-none h-full rounded-xl ${intl.locale === "ar" && "indent-6"}`}
            />
            <img src={"/icons/search.svg"} alt='search icon' className='absolute top-1/2 right-2 -translate-y-1/2' />
        </div>
    );
}

import Button from "../../../shared/components/Button.tsx";
import Countries from "../../../features/accredited-universities/components/Countries.tsx";
import CustomSearch from "../../../shared/components/CustomSearch.tsx";
import Degrees from "../../../features/accredited-universities/components/Degrees.tsx";
import GeneralSpecializations from "../../../features/accredited-universities/components/GeneralSpecializations.tsx";
import React, { useContext } from "react";
import Universities from "../../../features/accredited-universities/components/Universities.tsx";
import { handleSendMessage } from "../../../utils/ForwordToWhatsapp.ts";
import { useIntl } from "react-intl";
import { AppContext } from "../providers.tsx";

export default function AccreditedUniversitiesPage() {
  const intl = useIntl();
  const { locale, messages } = intl;
  const t = messages.common;

  const { setSearch, search } = useContext(AppContext);

  return (
    <main className={`${locale === "ar" ? "cairo" : "quicksand"} max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex flex-col gap-6 mt-10 max-[410px]:px-5 max-[370px]:px-3 mb-10`}>
      <div className="w-full flex items-center justify-between">
        <h1 className="font-medium lg:text-4xl text-2xl text-[#0D1216] ">
          {t.accreditedUniversities}
        </h1>
        {/* <CustomSearch setSearch={setSearch} search={search} /> */}
      </div>
      <Countries messages={t} tCountries={t.checkAll} />
      <Degrees tDegrees={t.degree} />
      <GeneralSpecializations title={t.generalSpecialization} />

      <Universities />

      <div className="flex flex-col gap-3">
        <p className="text-2xl text-[#333333] font-medium mb-6 max-[600px]:text-xl">
          {t.contactInfoSpec}
        </p>
        <Button
          icon={"/icons/global_whatsapp_btn.svg"}
          iconHeight={32}
          iconWidth={32}
          title={t.contactUs}
          className="text-white bg-[#22B14C] px-[40px] py-[10px] border-[#22B14C] hover:text-[#22B14C] hover:bg-white"
          link={handleSendMessage()}
          whatsapp={true}
        />
      </div>
    </main>
  );
}

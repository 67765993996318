import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Divider ( { className }: { className?: string } )
{

    const newClassName = twMerge( 'w-full h-[1px] bg-[#78848C]/40', className )

    return (
        <div className={ newClassName } ></div>
    )
}

import React from 'react';
import SubSpMediaWrapper from './SubSpMediaWrapper.tsx';
import { FormattedMessage, useIntl } from 'react-intl';

export default function SubSpecialties({ title }: { title: string }) {
    const {messages} = useIntl()

    return (
        <SubSpMediaWrapper
            checkTr={messages.common.checkAll}
            title={title}
        />
    );
}

import { fetchApi } from "../../../utils/fetchService.ts";

export const fetchSpecializedCountries = async ( locale: string, query?: string ) =>
{
    const response = await fetchApi( "/get_specialized_country", {
        headers: {
            "x-api-key": "1234"
        }
    }, locale, query );
    console.log(response)
    return response
}



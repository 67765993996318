import GSMediaWrapper from './GSMediaWrapper.tsx'
import React from 'react'
import { useIntl } from 'react-intl'

export default function GeneralSpecializations ( { title }: { title: string } )
{
    const intl = useIntl();
    const t = intl.messages.common.checkAll
    return (
        <GSMediaWrapper title={ title } checkTr={ t } />

    )
}

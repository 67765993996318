import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../shared/components/Button.tsx';
import Divider from '../../../shared/components/Divider.tsx';
import SwiperSpecializations from './SwiperSpecializations.tsx';
import { fetchSpecializeMajor } from '../../accredited-universities/api/fetchSpecializeMajor.ts';


export default function AccreditedSpecializations() {
    const intl = useIntl();
    const { messages } = intl;


    const locale = intl.locale;

    const t = messages.home.accreditedSpecializations;
    const tSpecializations = messages.home.accreditedSpecializations.specializations;

    const SPECIALIZATION_CARDS = [{
        id: 1,
        title: tSpecializations.firstSpecialization.title,
        image: "/images/specialty-1.jpg",
        imageAlt: tSpecializations.firstSpecialization.imageAlt,
        tags: [{
            id: 1,
            title: tSpecializations.firstSpecialization.tags.firstTag
        }, {
            id: 2,
            title: tSpecializations.firstSpecialization.tags.secondTag
        }, {
            id: 3,
            title: tSpecializations.firstSpecialization.tags.thirdTag
        }],
        linkName: tSpecializations.firstSpecialization.linkName,
        linkHref: `/accredited-specializations`,
    }, {
        id: 2,
        title: tSpecializations.secondSpecialization.title,
        image: "/images/specialty-2.jpg",
        imageAlt: tSpecializations.secondSpecialization.imageAlt,
        tags: [{
            id: 1,
            title: tSpecializations.secondSpecialization.tags.firstTag
        }, {
            id: 2,
            title: tSpecializations.secondSpecialization.tags.secondTag
        }, {
            id: 3,
            title: tSpecializations.secondSpecialization.tags.thirdTag
        }],
        linkName: tSpecializations.secondSpecialization.linkName,
        linkHref: `/accredited-specializations`,
    }, {
        id: 3,
        title: tSpecializations.thirdSpecialization.title,
        image: "/images/specialty-1.jpg",
        imageAlt: tSpecializations.thirdSpecialization.imageAlt,
        tags: [{
            id: 1,
            title: tSpecializations.thirdSpecialization.tags.firstTag
        }, {
            id: 2,
            title: tSpecializations.thirdSpecialization.tags.secondTag
        }, {
            id: 3,
            title: tSpecializations.thirdSpecialization.tags.thirdTag
        }],
        linkName: tSpecializations.thirdSpecialization.linkName,
        linkHref: `/accredited-specializations`,
    }, {
        id: 4,
        title: tSpecializations.fourthSpecialization.title,
        image: "/images/specialty-2.jpg",
        imageAlt: tSpecializations.fourthSpecialization.imageAlt,
        tags: [{
            id: 1,
            title: tSpecializations.fourthSpecialization.tags.firstTag
        }, {
            id: 2,
            title: tSpecializations.fourthSpecialization.tags.secondTag
        }, {
            id: 3,
            title: tSpecializations.fourthSpecialization.tags.thirdTag
        }],
        linkName: tSpecializations.fourthSpecialization.linkName,
        linkHref: `/accredited-specializations`,
    }, {
        id: 5,
        title: tSpecializations.fifthSpecialization.title,
        image: "/images/specialty-2.jpg",
        imageAlt: tSpecializations.fifthSpecialization.imageAlt,
        tags: [{
            id: 1,
            title: tSpecializations.fifthSpecialization.tags.firstTag
        }, {
            id: 2,
            title: tSpecializations.fifthSpecialization.tags.secondTag
        }, {
            id: 3,
            title: tSpecializations.fifthSpecialization.tags.thirdTag
        }],
        linkName: tSpecializations.fifthSpecialization.linkName,
        linkHref: `/accredited-specializations`,
    }]

    const [cards, setCards] = useState(SPECIALIZATION_CARDS)


    useEffect(() => {
        fetchSpecializeMajor(locale, "&start=1").then((data) => {
            if (data.error === "0") {
                let newData = data.majors;

                console.log(newData)

                newData = newData.map((items,index) => {
                    let temp = {
                        title: items.name,
                        image:  index % 2 === 0 ? "/images/specialty-2.jpg" : "/images/specialty-1.jpg",
                        imageAlt: tSpecializations.fourthSpecialization.imageAlt,
                        tags: items.degrees.map((item, index) => ({ id: index, title: item })),
                        linkName: tSpecializations.fifthSpecialization.linkName,
                        linkHref: `/accredited-specializations`,
                    };

                    return temp;

                })

                setCards(newData)

            }

        })

    }, [locale])




    return (
        <section className={`${locale === "ar" ? "cairo" : "quicksand"} w-full flex flex-col my-0`} >
            <div className='flex flex-col gap-5 max-[800px]:px-8' >
                <h2 className='text-center text-[#2E3A59] text-[2.5rem] font-bold max-[805px]:text-3xl' >{t.title}</h2>
                <div className='flex max-w-[1440px] justify-between w-full mx-auto max-[1000px]:flex-col max-[1000px]:justify-center max-[1000px]:items-center max-[1000px]:gap-5' >
                    <div className={`flex flex-col gap-2 px-3 ${locale === "ar" ? "border-l-[1.3px]" : "border-r-[1.3px]"}  border-[#78848C] max-[1000px]:border-none basis-1/3 justify-center items-center`} >
                        <span className='text-lg font-medium text-[#313C45] max-[805px]:text-sm' >{t.options.firstOption.preTitle}</span>
                        <span className='text-2xl font-bold text-[#0D1216] max-[805px]:text-xl text-center' >{t.options.firstOption.title}</span>
                    </div>
                    <Divider className='w-[20.5px] h-[2px] rounded-full bg-[#78848c71] hidden max-[1000px]:block' />
                    <div className={`flex flex-col gap-2 px-3 ${locale === "ar" ? "border-l-[1.3px]" : "border-r-[1.3px]"}   border-[#78848C] max-[1000px]:border-none basis-1/3 justify-center items-center`} >
                        <span className='text-lg font-medium text-[#313C45] max-[805px]:text-sm' >{t.options.secondOption.preTitle}</span>
                        <span className='text-2xl font-bold text-[#0D1216] max-[805px]:text-xl text-center' >{t.options.secondOption.title}</span>
                    </div>
                    <Divider className='w-[20.5px] h-[2px] rounded-full bg-[#78848c71] hidden max-[1000px]:block' />
                    <div className='flex flex-col gap-2 px-3 basis-1/3 justify-center items-center' >
                        <span className='text-lg font-medium text-[#313C45] max-[805px]:text-sm' >{t.options.thirdOption.preTitle}</span>
                        <span className='text-2xl font-bold text-[#0D1216] max-[805px]:text-xl text-center' >{t.options.thirdOption.title}</span>
                    </div>
                </div>
            </div>
            <section className='w-full' >
                <SwiperSpecializations specializations={cards} />
            </section>
            <div className='flex items-center flex-col gap-8 justify-center max-[800px]:px-10' >
                <Button title={t.btnText} className='w-fit px-[20px] py-[11.5px] text-base font-bold' link='/accredited-specializations' />
                {/* <div className='flex items-center gap-3' >
                    <Image src={ ring } height={ 22 } width={ 22 } alt='ring icon' />
                    <div className='flex items-center gap-1' >
                        <span className='text-[#313C45]' >{ t( "info" ) }</span>
                        <span className='underline text-[#313C45]' >{ t( "duration" ) }</span>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

import React, { useContext, useEffect, useMemo, useState } from 'react';
import Checkbox from '../../../shared/components/Checkbox.tsx';

import { fetchSpecializedCountries } from '../api/fetchSpecializedCountries.ts';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../app/[locale]/providers.tsx';
import { useLocation } from 'react-router-dom';
import CustomSearch from '../../../shared/components/CustomSearch.tsx';
import { useWindowSize } from 'usehooks-ts';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ArrowRight from '../../../shared/components/ArrowRight.tsx';
import { twMerge } from 'tailwind-merge';
import ArrowLeft from '../../../shared/components/ArrowLeft.tsx';


export default function Countries({ tCountries, messages }: { tCountries: string, messages: Object }) {
    const locale = useIntl().locale;
    const { country_id, setCountryId, degree_id, specialization_id, specific_specialization_id, countrySearch, setCountrySearch } = useContext(AppContext);
    const [allChecked, setAllChecked] = useState(false);
    const { width } = useWindowSize();

    const getNumberOfSlides = () => {
        if (width > 1024) return 8;
        if (width > 900) return 6;
        if (width > 600) return 2;
        return 1;
    };

    let slidesPerView = getNumberOfSlides();

    let sliderClassName = `gap-4 max-[700px]:w-[100%] max-[600px]:w-[100%] max-[600px]:gap-2 max-[370px]:gap-1 max-[360px]:gap-5 max-[360px]:w-[100%] ${locale === 'ar' ? "max-[600px]:pr-2" : "max-[600px]:pl-2"}`
    let wrapperClassName = 'max-[360px]:flex-col max-[360px]:gap-10 items-start'


    console.log(country_id, degree_id, specialization_id, specific_specialization_id)
    const location = useLocation();

    const { data: countries } = useQuery<ArrayResponseType<{
        id: string;
        country_image: string;
        country_name: string;
        country_short_image: string;
    }>, unknown>({
        queryKey: ['countries', locale, location, countrySearch],
        queryFn: () => fetchSpecializedCountries(locale, `&country_id=${country_id.length > 0 ? country_id.join(",") : ""}&degree_id=${degree_id.length > 0 ? degree_id.join(",") : ""}&specialization_id=${specialization_id.length > 0 ? specialization_id.join(",") : ""}&subspecialization_id=${specific_specialization_id.length > 0 ? specific_specialization_id.join(",") : ""}&search=${countrySearch}`),
    });

    useEffect(() => {
        if (country_id[0] === "all") {
            if (!allChecked) setAllChecked(true);
        } else {
            if (allChecked) {
                setAllChecked(false)
            };

        }
    }, [country_id, degree_id, specialization_id, specific_specialization_id, allChecked]);

    const newWrapperClassName = twMerge('items-center  min-[768px]:flex max-[768px]:flex-col  max-[768px]:gap-6');
    const newSliderClassName = twMerge("w-[90%] min-[768]:max-w-[90%] relative px-0 max-[768]:max-w-[100%] max-[768px]:px-0 flex items-center",);
    const checkboxId = 'countries'
    const searchMobileCSS = twMerge('')

  

    useEffect(() => {
        setAllChecked(false)
        setCountryId([])
    }, [countrySearch])

    useEffect(()=>{
        if(country_id && countries && country_id.length > 1 && country_id.length === countries?.country.length){
            setCountryId(["all"])
        }
    },[country_id])


    console.log(slidesPerView)

    return (
        <section className="flex-col gap-3">
            <div className="flex gap-4 mb-6 items-center flex-wrap">
                <h2 className='text-xl font-medium text-[#313C45] max-[600px]:text-lg'>{messages.countries}</h2>
                <div className="flex items-center gap-4 max-[600px]:gap-1 justify-around rounded-xl border-[1.04px] border-[#D0D5DD] max-w-[147px] px-3 py-[0.6rem]">
                    <Checkbox
                        id="countries"
                        checked={allChecked}
                        onChange={() => {
                            if (countries) {
                                setCountryId((prev: Array<string>) =>
                                    allChecked
                                        ? []
                                        : ["all"]
                                );
                                setAllChecked((prev) => !prev);
                            }
                        }}
                    />
                    <label htmlFor="countries" className='font-medium'>{tCountries}</label>
                </div>
                <CustomSearch search={countrySearch} setSearch={setCountrySearch} searchMobileCSS={searchMobileCSS} />
            </div>

            <section className={newWrapperClassName} style={{ alignItems: "center" }}>
                <section className='w-full flex items-center'>
                    {locale === "ar" ? <ArrowRight className={`swiper-button-prev-${checkboxId}`} /> : <ArrowLeft className={`swiper-button-prev-${checkboxId}`} />}
                    <Swiper
                        className='w-full '
                        spaceBetween={0}
                        slidesPerView={slidesPerView}
                        navigation={{
                            nextEl: `.swiper-button-next-${checkboxId}`,
                            prevEl: `.swiper-button-prev-${checkboxId}`,
                        }}
                        breakpoints={{
                            440: {
                                slidesPerView: 3,
                            },
                            640: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: slidesPerView,
                            },
                        }}
                        modules={[Navigation]}
                        dir={locale === "ar" ? "rtl" : "ltr"}
                    >
                        {countries &&
                            countries?.country?.map((country, index, arr) => (
                                <SwiperSlide
                                    key={country?.id}
                                >
                                    <div
                                        key={country.id}
                                        onClick={() => {
                                            setCountryId((prev: Array<string>) =>
                                                prev.includes(country.id)
                                                    ? prev.filter(
                                                        (item) => item !== country.id && item !== "all"
                                                    )
                                                    : country_id[0] === "all" ?  [ ...countries?.country?.filter(items => items?.id !== country?.id).map((item)=>item?.id)] : [...prev, country.id]
                                            );
                                        }}
                                        className={`cursor-pointer flex flex-col gap-2 min-w-[93px] h-[78px] items-center justify-center border-[1.04px] border-[#D0D5DD] ${index === 0
                                            ? locale === 'en'
                                                ? 'rounded-l-xl'
                                                : 'rounded-r-xl'
                                            : ''
                                            } ${index === arr.length - 1
                                                ? locale === 'en'
                                                    ? 'rounded-r-xl'
                                                    : 'rounded-l-xl'
                                                : ''
                                            } ${index !== 0 &&
                                            index !== arr.length - 1 &&
                                            ''
                                            } ${(country_id as Array<string>)?.includes(
                                                country.id
                                            ) && 'bg-[#2775FF] text-[#fff]'
                                            } ${allChecked ? "bg-[#2775FF] text-[#fff]" : ""}`}
                                    >
                                        <img
                                            src={country.country_short_image}
                                            height={10}
                                            width={30}
                                            alt="icon flag"
                                            className="w-[40px] h-[30px] rounded"
                                        />
                                        <span className="px-3 font-medium">{country.country_name}</span>
                                    </div>
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    {locale === "ar" ? <ArrowLeft className={`swiper-button-next-${checkboxId}`} /> : <ArrowRight className={`swiper-button-next-${checkboxId}`} />}
                </section>
            </section>


        </section>
    );
}

import { fetchApi } from "../../../utils/fetchService.ts";

export const fetchDegrees = async ( locale: string, query : string ) =>
{
    const response = await fetchApi( "/get_degree", {
        headers: {
            "x-api-key": "1234"
        }
    }, locale, query );
    console.log(response)
    return response;
} 
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../shared/components/Button.tsx';
import Countries from '../../../features/accredited-specializations/components/Countries.tsx';
import CustomSearch from '../../../shared/components/CustomSearch.tsx';
import Degrees from '../../../features/accredited-universities/components/Degrees.tsx';
import GeneralSpecializations from '../../../features/accredited-universities/components/GeneralSpecializations.tsx';
import SubSpecialties from '../../../features/accredited-specializations/components/SubSpecialties.tsx';
import { handleSendMessage } from '../../../utils/ForwordToWhatsapp.ts';
import { AppContext } from '../providers.tsx';


export default function  AccreditedSpecializationsPage() {
    const intl = useIntl();
    const locale = intl.locale;
    const { setSearch, search, specSearch, degreeSearch, subSpec } = useContext(AppContext);

    return (
        <main className={`${locale === "ar" ? "cairo" : "quicksand"} max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex flex-col gap-4 mt-10 max-[410px]:px-5 max-[370px]:px-3 mb-10`}>
            <div className='w-full flex items-center justify-between'>
                <h1 className='font-medium lg:text-4xl text-[#0D1216] text-2xl'>
                    {intl.messages.common.accreditedSpecializations}
                </h1>
                {/* <CustomSearch setSearch={setSearch} search={search} /> */}
            </div>
            <Degrees tDegrees={intl.messages.common.degree} />
            <GeneralSpecializations title={intl.messages.common.generalSpecialization} />
            <SubSpecialties title={intl.messages.common.subSpecialties} />
            <Countries />
            <div className='flex flex-col gap-3'>
                <p className='text-2xl text-[#333333] font-medium mb-6 max-[600px]:text-xl'>
                {intl.messages.common.contactInfo}
                </p>
                <Button
                    icon={"/icons/whatsapp.svg"}
                    iconHeight={32}
                    iconWidth={32}
                    title={intl.messages.common.contactUs}
                    className='text-white bg-[#22B14C] border-[#22B14C] hover:text-[#22B14C] hover:bg-white'
                    link={handleSendMessage()}
                    whatsapp={true}
                />
            </div>
        </main>
    );
}

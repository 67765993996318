import { BASE_URL, fetchApi } from "../../../utils/fetchService.ts";

export const fetchGeneralSpecializations = async ( locale: string, query : string ) =>
{
    const response = await fetchApi( "/get_general_specialization", {
        headers: {
            "x-api-key": "1234"
        }
    }, locale ,query);
    console.log(response)
    return response;
}
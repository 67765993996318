import Cards from '../../../features/services/components/Cards.tsx'
import React from 'react'
import { useIntl } from 'react-intl'
export default function ServicesPage ()
{

    const t = useIntl().messages.services
    const { locale } = useIntl();

    return (
        <main className={`${locale === "ar" ? "cairo" : "quicksand"} max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex flex-col gap-10 mt-10 max-[410px]:px-5 max-[370px]:px-3 mb-10  `} >
            <div className='flex flex-col gap-2 items-center max-[800px]:items-start' >
                <h1 className={`text-center text-[#0D1216] text-[2.5rem] font-medium max-[600px]:text-3xl  ${locale === 'ar'? 'max-[430px]:text-right': 'max-[430px]:text-left'}`} >{ t.title }</h1>
                <p className={`text-center text-[#313C45] font-medium text-xl max-[600px]:text-lg ${locale === 'ar'? 'max-[430px]:text-right': 'max-[430px]:text-left'}`} >{ t.description }</p>
            </div>
            <Cards />
        </main>
    )
}

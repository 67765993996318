import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../shared/components/Button.tsx';

export default function SubFooter() {
    const intl = useIntl();
    const { messages, locale } = intl;
    const t = (data) => messages.home.subFooter[data];

    console.log(t(""))


    return (
        <section className='bg-[url("/images/sub-footer-home-bg.jpg")] bg-center bg-cover w-full flex items-center flex-col gap-7 max-[800px]:gap-3 justify-center min-h-[650px] max-[600px]:min-h-full max-[600px]:my-20 max-[1150px]:bg-contain max-[1150px]:bg-top bg-no-repeat max-[600px]:px-5'>
            <p className='flex items-center gap-2 text-[#373050]'>
                {locale === 'ar' ? 
                <span className='text-6xl font-normal max-[800px]:text-2xl'>”{t("logoName")}“</span> 
                : 
                    <span className='text-6xl font-normal max-[800px]:text-2xl'>{t("logoName")}</span>
                }
                <span className='text-6xl font-normal max-[800px]:text-2xl'>{t("preTitle")}</span>
            </p>
            <h2 className='text-[#373050] text-6xl font-semibold max-[800px]:text-2xl'>{t("title")}</h2>
            <span className='text-[#0D1216] font-normal text-xl max-w-[50%] max-[800px]:text-lg text-center'>{t("description")}</span>
            <Button title={t("btnText")} className='text-[#FFFAFA] text-base font-bold' link={`/contact-us`} />
        </section>
    );
}

import React from 'react';
import NavLinks from './NavLinks.tsx';
import NavLinksWrapper from './NavLinksWrapper.tsx';
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
// import logo from '/icons/logo.png';

export default function Header() {
  const intl = useIntl();
  const { messages, locale } = intl;
  return (
    <header className={`${locale === "ar" ? "cairo" : "quicksand"} flex items-center gap-4 max-w-[1700px] mx-auto max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mt-6 max-[410px]:px-5 max-[370px]:px-3`}>
      <div className='basis-[50%]'>
        <Link to='/'> {/* Replace Link with anchor tag */}
          <img src="/icons/logo.png"  alt='tasheel logo' className='md:w-[16vw] lg:w-[10vw]' />
        </Link>
      </div>
      <NavLinksWrapper />
    </header>
  );
}

import React from 'react';
import AccreditedSpecializations from '../../features/home/components/AccreditedSpecializations.tsx';
import Certification from '../../features/home/components/Certification.tsx';
import HeroSection from '../../features/home/components/HeroSection.tsx';
import SomeAccreditedUniversities from '../../features/home/components/SomeAccreditedUniversities.tsx';
import SubFooter from '../../features/home/components/SubFooter.tsx';
import { useIntl } from "react-intl";

function HomePage() {
  const intl = useIntl();

  const locale = intl.locale;

  return (
    <main className={`${locale === "ar" ? "cairo" : "quicksand"} w-full flex flex-col`}>
      <section className="max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex flex-col gap-10 mt-10 max-[410px]:px-5 max-[370px]:px-3 my-20">
        <HeroSection />
      </section>
      <AccreditedSpecializations />
      <SomeAccreditedUniversities />
      <Certification />
      <SubFooter />
    </main>
  );
}

export default HomePage;

'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createContext, useEffect, useState } from 'react';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLocation } from 'react-router-dom';

const defaultValues = {
    country_id: [],
    degree_id: [],
    specialization_id: [],
    specific_specialization_id: [],
    search: "",
    setSearch: (value: any) => { },
    setCountryId: (value: any) => { },
    setDegreeId: (value: any) => { },
    setSpecializationId: (value: any) => { },
    setSpecificSpecializationId: (value: any) => { },
    degreeSearch: "",
    setDegreeSearch: (value: any) => { },
    specSearch: "",
    setSpecSearch: (value: any) => { },
    subSpec: "",
    setSubSpecSearch: (value: any) => { },
    countrySearch: "",
    setCountrySearch: (value: any) => { },
}

export const AppContext = createContext(defaultValues);


export default function Providers({ children }: { children: React.ReactNode }) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // With SSR, we usually want to set some default staleTime
                        // above 0 to avoid refetching immediately on the client
                        staleTime: 60 * 1000,
                    },
                },
            })
    );



    const [country_id, setCountryId] = useState([]);
    const [degree_id, setDegreeId] = useState([]);
    const [specialization_id, setSpecializationId] = useState([]);
    const [specific_specialization_id, setSpecificSpecializationId] = useState([]);
    const [search, setSearch] = useState("");
    const [degreeSearch, setDegreeSearch] = useState("");
    const [specSearch, setSpecSearch] = useState("");
    const [subSpec, setSubSpecSearch] = useState("");
    const [countrySearch, setCountrySearch] = useState("");

    const location = useLocation();

    useEffect(() => {
        setCountryId([])
        setDegreeId([])
        setSpecializationId([])
        setSpecificSpecializationId([])
        setSearch("")
        setSubSpecSearch("")
        setSpecSearch("")
        setDegreeSearch("")
        setCountrySearch("")
    }, [location])



    return (
        <QueryClientProvider client={queryClient} contextSharing={true} >
            <AppContext.Provider value={{
                country_id, degree_id, specialization_id, setCountryId, setDegreeId, setSpecializationId, specific_specialization_id, setSpecificSpecializationId, search, setSearch, degreeSearch,
                setDegreeSearch,
                specSearch,
                setSpecSearch,
                subSpec,
                setSubSpecSearch, countrySearch, setCountrySearch
            }} >
                {children}
            </AppContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

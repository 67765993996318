import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DegreesMediaWrapper from './DegreesMediaWrapper.tsx';

export default function Degrees({ tDegrees }: { tDegrees: any }) {
    const intl = useIntl();
    console.log(intl.messages)

    return (
        <DegreesMediaWrapper
            tDegrees={tDegrees}
            checkTr={intl.messages.common.checkAll}
        />
    );
}

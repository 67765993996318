import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Image from 'react-image';
import { twMerge } from 'tailwind-merge';
import { useIntl } from 'react-intl';
import { useLocale } from '../../store/LocaleContext';

export default function CustomListBox({ wrapperClassName }) {
  const intl = useIntl()
  const locale = intl.locale; // Assuming locale is "en" for demonstration

  const newWrapperClassName = twMerge(
    `relative top-2 z-50 w-[70px] h-[70px] rounded-full  ${locale === "ar" ? "left-0" : "right-0"}`,
    wrapperClassName
  );

  const languages = [
    {
      id: 1,
      href: locale === "ar" ? "/ar" : "/en",
      icon: "/icons/arabic-flag.svg",
      value: "arabic"
    },
    {
      id: 2,
      href: locale === "ar" ? "/en" : "/ar",
      icon: "/icons/english-flag.svg",
      value: "english"
    },
  ];

  const [selected, setSelected] = useState(locale === "ar" ? languages[0] : languages[1]);

  const { setLocale } = useLocale()

  const handleClick = (language) => {
    setSelected(language === "english" ? languages[1] : languages[0])
    
    if (language === "english") {
      setLocale("en")
      window.location.reload()
    } else {
      setLocale("ar")
      window.location.reload()
    }

  }

  return (
    <div className={newWrapperClassName}>
      
        <span className="relative mt-1">
          <button onClick={()=>handleClick(selected.value === "arabic" ? languages[1].value : languages[0].value)} className="relative bg-[#919EAB14] w-[50px] h-[50px] rounded-full p-2">
            <img onClick={()=>handleClick(selected.value === "arabic" ? languages[1].value : languages[0].value)} src={selected.value === "arabic" ? languages[1].icon : languages[0].icon} alt='lang icon'height={ 20 } width={ 28 }  className='w-full h-full' />
          </button>
         
        </span>
    
    </div>
  );
}

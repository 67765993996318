import { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { cairo, poppins, tajawal } from "./fonts.ts"; // Import your font definitions
import Footer from "../../layouts/Footer.tsx";
import Header from "../../layouts/Header.tsx";
import Providers from "./providers.ts";
import React from "react";

const APP_DEFAULT_TITLE = "Tasheel Platform";
const APP_TITLE_TEMPLATE = "%s - Tasheel Platform";
const APP_DESCRIPTION =
  "Platform for providing opportunities for students to study abroad!";

export const metadata = {
  title: {
    default: APP_DEFAULT_TITLE,
    template: APP_TITLE_TEMPLATE,
  },
  description: APP_DESCRIPTION,
};

export const viewport = {
  themeColor: "#FFFFFF",
};

const RootLayout = ({ children, params: { locale } }) => {
  useEffect(() => {
    // Set document properties
    document.title = metadata.title.default; // Set document title
    
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", metadata.description); // Set document description

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", viewport.themeColor); // Set document theme color

    // Set document language and direction based on locale
    document.documentElement.lang = locale;
    document.documentElement.dir = locale === "ar" ? "rtl" : "ltr";

    // Set body class based on locale
    document.body.className = `${
      locale === "ar" ? "quicksand" : "quicksand"
    } ${"quicksand"}`;

    
    
  }, [locale]);

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default RootLayout;

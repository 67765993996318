import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

export default function Card({
  card,
}: {
  card: {
    id: number;
    image: string;
    imageAlt: string;
    title: string;
    linkName: string;
    linkHref: string;
    tags: { id: number; title: string }[];
  };
}) {

  const intl = useIntl();

  const locale = intl.locale;
  return (
    <article
      className="p-0 w-full max-w-[390px] h-fit bg-[#FBFBFD] rounded-xl  max-[450px]:ml-2"
      style={{ boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)" }}
    >
      <div className="w-full flex justify-center items-center">
        <img
          src={card?.image}
          alt={card?.imageAlt}
          className="object-cover w[100%] max-h-[290px] h-[390px] max-[800px]:h-[225px] max-[450px]:h-auto rounded-t-xl"
        />
      </div>
      <section className="w-full p-6">
        <div className={`flex flex-col gap-4 overflow-hidden ${locale === 'ar' ? 'items-end': 'items-start' }`}>
          <h3 dir={`${locale === 'ar' ? "rtl":"ltr"}`} className={`text-3xl truncate font-bold  ${locale === 'ar' ? 'rtl  items-end max-w-[100%]': 'ltr items-start max-w-[100%]' }`}>{card?.title}</h3>
          <div className="flex items-center gap-3 overflow-hidden">
            <div className="flex flex-wrap">
              {card?.tags?.slice(0, 2).map((card) => (
                <span
                  className="text-[#00A1A7] mr-1 text-base text-sm font-semibold bg-[#00C4CC1A] rounded-xl p-2"
                  key={card?.id}
                >
                  {card?.title}
                </span>
              ))}
              {card?.tags.length > 2 && (
                <span className="text-[#00A1A7] text-base font-semibold bg-[#00C4CC1A] rounded-xl p-2">
                  {`+${card?.tags.length - 2}`}
                </span>
              )}
            </div>
          </div>
          <Link
            to={card?.linkHref}
            className="text-[#2775FF] font-semibold underline block mt-5"
          >
            {card?.linkName}
          </Link>
        </div>
      </section>
    </article>
  );
}


import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import ArrowLeft from '../../shared/components/ArrowLeft.tsx';
import ArrowRight from '../../shared/components/ArrowRight.tsx';
import Checkbox from '../../shared/components/Checkbox.tsx';
import { twMerge } from 'tailwind-merge';
import { useLocale } from '../../store/LocaleContext.js';
import CustomSearch from '../../shared/components/CustomSearch.tsx';

export default function MultiChoiceSlider({ allChecked, setAllChecked, subSpec, choices, title, checkboxId, sliderClassName, slidesPerView = '4', checkTr, wrapperClassName, choicesChecked, setChoicesChecked, singleChoice, search, setSearch }) {


    useEffect(() => {
        if (choicesChecked[0] === "all") {
            if (!allChecked) setAllChecked(true);
        } else {
            if (allChecked) setAllChecked(false);
        }
    }, [choicesChecked, choices, allChecked]);

    const handleCheckAll = () => {
        setAllChecked(prev => !prev);
        setChoicesChecked(prev => !allChecked ? ["all"] : []);
    };

    const { locale } = useLocale();

    useEffect(() => {
        setAllChecked(false)
        setChoicesChecked([])
    }, [search])


    useEffect(() => {
        if (choices && choicesChecked && choices.lenghth > 1 && choices.length === choicesChecked.length) {
            setAllChecked(true)
            setChoicesChecked(["all"])
        }
    }, [choicesChecked])



    const newWrapperClassName = twMerge('items-center  min-[768px]:flex max-[768px]:flex-col  max-[768px]:gap-6', wrapperClassName);
    const newSliderClassName = twMerge("w-[90%] min-[768]:max-w-[90%] relative px-0 max-[768]:max-w-[100%] max-[768px]:px-0 flex items-center", sliderClassName);
    console.log(allChecked)

    return (
        <section className='w-full overflow-hidden multiCoiseSlider'>
            <div className="mobile-only flex gap-4 mb-6 items-center flex-wrap">
                <h2 className='text-xl font-medium text-[#313C45] max-[600px]:text-lg'>{title}</h2>
                {!singleChoice && <div className='flex items-center max-[768px]:py-6'>
                    <div className='flex items-center gap-4 justify-around rounded-xl border-[1.04px] border-[#D0D5DD] max-w-[147px] px-3 py-2'>
                        <Checkbox id={checkboxId} checked={allChecked} onChange={handleCheckAll} />
                        <label htmlFor={checkboxId} className='text-lg font-medium whitespace-nowrap'>{checkTr}</label>
                    </div>
                </div>}
                <CustomSearch search={search} setSearch={setSearch} />
            </div>
            <section className={newWrapperClassName} style={{ alignItems: "center" }}>
                <section className={newSliderClassName}>
                    {locale === "ar" ? <ArrowRight className={`swiper-button-prev-${checkboxId}`} /> : <ArrowLeft className={`swiper-button-prev-${checkboxId}`} />}
                    <Swiper
                        className={`w-full`}
                        dir={locale === "ar" ? "rtl" : "ltr"}
                        spaceBetween={20}
                        slidesPerView={slidesPerView}
                        navigation={{
                            nextEl: `.swiper-button-next-${checkboxId}`,
                            prevEl: `.swiper-button-prev-${checkboxId}`,
                        }}
                        modules={[Navigation]}

                    >
                        {(choices || []).map(choice => (
                            <SwiperSlide
                                key={choice?.id}
                                className={`font-semibold rounded-xl p-2 cursor-pointer flex w-full items-center justify-center text-center ${allChecked ? "bg-[#2775FF] text-[#fff]" : choicesChecked?.includes(choice?.id) ? "bg-[#2775FF] text-[#fff]" : "bg-[#f2f6fb]"}`}
                                onClick={() => singleChoice ? setChoicesChecked(prev => prev[0] === choice?.id ? [] : [choice?.id]) : setChoicesChecked(prev => prev?.find(item => item === choice?.id) ? prev?.filter(item => item !== choice?.id && item !== "all") : allChecked ? [...choices.filter(items => items.id !== choice?.id).map((item)=>item.id)] : [...prev, choice?.id])}
                            >
                                {choice?.name}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {locale === "ar" ? <ArrowLeft className={`swiper-button-next-${checkboxId}`} /> : <ArrowRight className={`swiper-button-next-${checkboxId}`} />}
                </section>
            </section>
        </section>
    );
}

import React from 'react'

export default function ArrowLeft ( { className }: { className?: string } )
{
    return (
        <svg className={ className } width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0561523" y="0.939941" width="28" height="28" rx="12" fill="#F9FAFB" />
            <path d="M15.7229 19.9399L10.7229 14.9399L15.7229 9.93994L16.8896 11.1066L13.0562 14.9399L16.8896 18.7733L15.7229 19.9399Z" fill="#1B1C57" />
        </svg>

    )
}

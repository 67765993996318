import React from 'react';
import { useIntl } from 'react-intl';

export default function Certification() {

    const intl = useIntl();

     const { locale } = intl;
    const convert = intl.messages.home.certification;
    const t = convert;

    console.log(convert)

    return (
        <section className='w-full max-w-[1160px] max-[1100px]:px-5 max-[500px]:px-5 mx-auto mt-10'>
            <article className='bg-gradient-blue max-w-[1000px] w-full mx-auto p-10 rounded-[20px] max-[500px]:p-5'>
                <span className="cairo text-white text-2xl font-bold">{t.title}</span>
                <div className='flex gap-2 w-full  flex-wrap items-center justify-center mt-5'>
                    <span className='cairo bg-white hover:bg-slate-100 duration-100 cursor-pointer ease-linear py-3 px-4 max-w-[450px] max-[805px]:max-w-full max-[390px]:text-lg w-full rounded text-[#1297FF] text-xl font-semibold font-cairo basis-1/2 max-[805px]:basis-full text-center'>{t.firstLine}</span>
                    <span className='cairo bg-white hover:bg-slate-100 duration-100 cursor-pointer ease-linear py-3 px-4 max-w-[450px] max-[805px]:max-w-full max-[390px]:text-lg w-full rounded text-[#1297FF] text-xl font-semibold font-cairo basis-1/2 max-[805px]:basis-full text-center'>{t.secondLine}</span>
                    <span className='cairo bg-white hover:bg-slate-100 duration-100 cursor-pointer ease-linear py-3 px-4 max-w-[450px] max-[805px]:max-w-full max-[390px]:text-lg w-full rounded text-[#1297FF] text-xl font-semibold font-cairo basis-1/2 max-[805px]:basis-full text-center'>{t.thirdLine}</span>
                    <span className='cairo bg-white hover:bg-slate-100 duration-100 cursor-pointer ease-linear py-3 px-4 max-w-[450px] max-[805px]:max-w-full max-[390px]:text-lg w-full rounded text-[#1297FF] text-xl font-semibold font-cairo basis-1/2 max-[805px]:basis-full text-center'>{t.fourthLine}</span>
                </div>
            </article>
            <p className='font-cairo leading-[89.95px] font-medium text-[#373050] text-5xl mt-5 text-center max-[800px]:text-3xl max-[390px]:text-2xl'>
                <span>{t.preDescription}{' '}</span>
                <span className='px-4 text-[#1196FF]'>{t.description}</span>
                <span>{t.subDescription}</span><br/>
                <span>{t.subDescription2}</span>
            </p>
            <div className='flex items-center justify-center mt-5'>
                <img src={"/images/how-to-apply/sixth-img.jpg"} height={112} width={185} alt={t.description} className='max-[800px]:scale-75' />
            </div>
        </section>
    );
}

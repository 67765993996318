import React, { useState } from 'react';
import Backdrop from '../shared/components/Backdrop.tsx';
import Button from '../shared/components/Button.tsx';
import CustomListBox from '../shared/components/CustomListBox.tsx';
import NavLinks from './NavLinks.tsx';

export default function MobileLinksWrapper({ navLinks, btnText, contactUs }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='hidden max-[1070px]:flex w-full items-center justify-end'>
      <img src="/icons/burger-menu.svg" height={44} width={44} alt='burger menu icon' className='cursor-pointer' onClick={() => setIsOpen(true)} />
      <div className={`hidden z-10 flex-col items-center gap-10 w-full fixed top-0 right-0 h-screen max-[1070px]:flex duration-200 ease-linear ${isOpen ? "translate-x-0" : "-translate-x-full"}`}>
        <Backdrop />
        <section className='relative w-full h-[70%] z-10 flex flex-col items-center justify-center gap-8'>
          <div className='flex items-center justify-between w-full px-5 border-b pb-8'>
            <img src="/icons/logo.png" height={"auto"} width={180} alt='tasheel logo' />
            <img src="/icons/close-menu.svg" height={40} width={40} alt='close menu icon' className='cursor-pointer' onClick={() => setIsOpen(false)} />
          </div>
          <section className='flex flex-col items-center justify-center gap-8 w-full px-5'>
            <NavLinks onClick={() => setIsOpen(false)} tNavLinks={navLinks} className='flex-col items-start z-20 gap-5' />
            <Button onClick={() => setIsOpen(false)} title={btnText} className='z-20 max-w-full rounded-xl' link='how-to-apply' />
            <Button title={contactUs} onClick={() => setIsOpen(false)} className='z-20 max-w-full rounded-xl bg-[#DCE8FF] text-[#2775FF] hover:text-white hover:bg-[#2775FF]' link='/contact-us' />
            <CustomListBox wrapperClassName='w-auto h-auto' />
          </section>
        </section>
      </div>
    </div>
  );
}

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "./Card.tsx";
import React from "react";
import { useLocale } from "../../../store/LocaleContext.js";

export default function SwiperUniversities({ universities }) {
  const locale = useLocale();
  
  return (
    <Swiper
      className="w-full"
      loop={true}
      breakpoints={{
        700: {
          slidesPerView: 4,
        },
        500: {
          slidesPerView: 3,
        },
        450: {
          // width: 576,
          slidesPerView: 2,
        },
      }}
      spaceBetween={20}
      dir={locale === "ar" ? "rtl" : "ltr"}
    >
      {universities?.map((card) => (
        <SwiperSlide
          key={card.id}
          className="py-10 min-w-[400px] max-[800px]:min-w-[300px]"
        >
          <Card card={card} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

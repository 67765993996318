import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppContext } from '../../../app/[locale]/providers.tsx';
import MultiChoiceSlider from '../../../shared/components/MultiChoiceSlider.tsx';
import { fetchSpecializeMajor } from '../../../features/accredited-universities/api/fetchSpecializeMajor.ts';
import { useQuery } from '@tanstack/react-query';
import { useWindowSize } from 'usehooks-ts';

export default function SubSpMediaWrapper({ checkTr, title }: { checkTr: string; title: string }) {
    const { width } = useWindowSize();
    const locale = useIntl().locale;
    const [allChecked, setAllChecked] = useState(false);

    const { specific_specialization_id, setSpecificSpecializationId, country_id, degree_id, specialization_id, subSpec, setSubSpecSearch } = useContext(AppContext);

    const { data: specificSpecializations } = useQuery({
        queryKey: ['specific-specializations', country_id, degree_id, specialization_id, locale, subSpec],
        queryFn: () => fetchSpecializeMajor(locale, `&country_id=${country_id.length > 0 ? country_id.join(",") : ""}&degree_id=${degree_id.length > 0 ? degree_id.join(",") : ""}&specialization_id=${specialization_id.length > 0 ? specialization_id.join(",") : ""}&subspecialization_id=${specific_specialization_id.length > 0 ? specific_specialization_id.join(",") : ""}&search=${subSpec}`)
    });

    const getNumberOfSlides = () => {
        if (width > 1024) return 4;
        if (width > 900) return 3;
        if (width > 600) return 2;
        return 1;
    };

    const choices = useMemo(() => {
        return specificSpecializations?.majors?.map(major => ({
            id: major?.id,
            name: major?.name
        }));
    }, [specificSpecializations?.majors, country_id, degree_id, specialization_id, specific_specialization_id]);

    return (
        <MultiChoiceSlider
            setAllChecked={setAllChecked}
            allChecked={allChecked}
            subSpec={true}
            search={subSpec}
            setSearch={setSubSpecSearch}
            choicesChecked={specific_specialization_id}
            setChoicesChecked={setSpecificSpecializationId}
            checkboxId='sub-specialties'
            choices={choices}
            title={title}
            checkTr={checkTr}
            sliderClassName={`gap-4 max-[700px]:w-[100%] max-[600px]:w-[100%] max-[600px]:gap-2 max-[370px]:gap-1 max-[360px]:gap-5 max-[360px]:w-[100%] ${locale === 'ar' ? "max-[600px]:pr-2" : "max-[600px]:pl-2"}`}
            wrapperClassName='max-[360px]:flex-col max-[360px]:gap-10 items-start'
            slidesPerView={getNumberOfSlides()}
        />
    );
}

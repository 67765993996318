import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppContext } from '../../../app/[locale]/providers.tsx';
import MultiChoiceSlider from '../../../shared/components/MultiChoiceSlider.tsx';
import { fetchGeneralSpecializations } from '../api/fetchGeneralSpecializations.ts';

import { useQuery } from '@tanstack/react-query';
import { useWindowSize } from 'usehooks-ts';

export default function GSMediaWrapper({ checkTr, title }: { checkTr: string, title: string }) {
    const { width } = useWindowSize();
    const locale = useIntl().locale;
    const { specialization_id, setSpecializationId, country_id, degree_id, specific_specialization_id, setSpecSearch, specSearch } = useContext(AppContext);
    const [allChecked, setAllChecked] = useState(false);
    
    const getNumberOfSlides = () => {
        if (width > 1024) return 4;
        if (width > 900) return 3;
        if (width > 600) return 2;
        return 1;
    };

    const { data: specializations }: {
        data: ArrayResponseType<{
            id: string,
            main_section_name: string,
        }> | undefined

    } = useQuery({
        queryKey: [`general-specializations`, degree_id, specific_specialization_id, locale, specSearch],
        queryFn: () => fetchGeneralSpecializations(locale, `&country_id=${country_id.length > 0 ? country_id.join(",") : ""}&degree_id=${degree_id.length > 0 ? degree_id.join(",") : ""}&specialization_id=${specialization_id.length > 0 ? specialization_id.join(",") : ""}&subspecialization_id=${specific_specialization_id.length > 0 ? specific_specialization_id.join(",") : ""}&search=${specSearch}`)
    });

    const choices = useMemo(() => {
        return specializations?.specialization?.map(specialization => ({
            id: specialization?.id,
            name: specialization?.main_section_name
        }));
    }, [specializations?.specialization, country_id, degree_id, specialization_id, specific_specialization_id]);

    return (
        <MultiChoiceSlider
            setAllChecked={setAllChecked}
            allChecked={allChecked}
            choicesChecked={specialization_id}
            setChoicesChecked={setSpecializationId}
            checkTr={checkTr}
            checkboxId='general-specialization'
            choices={choices}
            title={title}
            sliderClassName={`gap-4 max-[700px]:w-[100%] max-[600px]:w-[100%] max-[600px]:gap-2 max-[370px]:gap-1 max-[360px]:gap-5 max-[360px]:w-[100%] ${locale === 'ar' ? "max-[600px]:pr-2" : "max-[600px]:pl-2"}`}
            wrapperClassName='max-[360px]:flex-col max-[360px]:gap-10 items-start'
            slidesPerView={getNumberOfSlides()}
            singleChoice={true}
            search={specSearch}
            setSearch={setSpecSearch}
        />
    );
}

import { useIntl } from "react-intl";

import Button from "../../../shared/components/Button.tsx";

import React from "react";

export default function HeroSection() {
  const intl = useIntl();

  const { messages, locale } = intl;

  const tCommon = (data) => messages.common[data];
  const tHeroSection = (data) => messages.home.heroSection[data];
  const tHeader = (data) => messages.header[data];

  return (
    <section className={`${locale === "ar" ? "cairo" : "quicksand"} w-full min-h-[500px] flex items-center justify-center`}
    >
      <section className="flex  w-full gap-4 justify-between  max-[1300px]:flex-col max-[1300px]:gap-10">
        <section className="relative basis-[60%] max-[1300px]:basis-full  w-full   flex items-center justify-center">
          <img
            src="/images/hero-home-bg.jpg"
            alt="hero background"
            className={`absolute -z-10 -top-10 lg:${
              locale === "ar" ? "-right-16" : "-left-16"
            } sm:${locale === "ar" ? "-right-0" : "-left-0"} `}
          />

          <article className="flex flex-col gap-5 max-[1300px]:items-start w-full">
            <h1 className={`${locale === "ar" ? "cairo" : "quicksand"} gradient-blue text-6xl font-[700] leading-snug font-cairo max-[1300px]:text-start max-[800px]:text-4xl`}>
              {tHeroSection("title")}
            </h1>
            <p className={`${locale === "ar" ? "cairo" : "quicksand"} text-[#0D1216] font-[700] text-5xl font-cairo max-[1300px]:text-start max-[800px]:text-3xl`}>
              {tHeroSection("subTitle")}
            </p>
            <h2 className={`${locale === "ar" ? "cairo" : "quicksand"} gradient-blue text-6xl font-[500] leading-relaxed font-cairo max-[1300px]:text-start max-[800px]:text-4xl`}>
              {tHeroSection("tag")}
            </h2>
            {/* <p className='text-xl text-[#0D1216] font-normal max-[1300px]:text-center max-[800px]:text-lg' >{ tHeroSection( 'description.firstLine' ) }</p> */}
            {/* <p className='text-xl text-[#0D1216] font-normal max-[1300px]:text-center max-[800px]:text-lg' >{ tHeroSection( 'description.secondLine' ) }</p> */}
            <div className="flex items-center gap-3 mt-5 w-full max-[820px]:flex-col max-[820px]:items-stretch">
              <Button
                className="font-bold min-w-[250px] max-w-full w-fit text-[#4D4C5A] border-none hover:text-white bg-[#F0F0F2] hover:bg-[#2775FF] max-[800px]:w-full max-[800px]:rounded-[8px]  max-[800px]:bg-[#2775FF] max-[800px]:text-white"
                title={tCommon("accreditedUniversities")}
                link={`accredited-universities`}
              />
              <Button
                className="font-bold min-w-[270px] max-w-full w-fit text-[#4D4C5A] border-none hover:text-white bg-[#F0F0F2] hover:bg-[#2775FF] max-[800px]:w-full max-[800px]:rounded-[8px]"
                title={tCommon("accreditedSpecializations")}
                link={`accredited-specializations`}
              />
              <Button
                className="font-bold min-w-[180px] max-w-full text-[#4D4C5A] border-none hover:text-white bg-[#F0F0F2] hover:bg-[#2775FF] max-[800px]:w-full max-[800px]:rounded-[8px]"
                title={tHeader("btnText")}
                link={`how-to-apply`}
              />
            </div>
          </article>
        </section>
        <article className="basis-[40%] w-full flex flex-col gap-6 max-[1300px]:basis-full max-[1300px]:items-center">
          <img
            src={
              locale === "ar"
                ? "/images/home-hero-img-ar.jpg"
                : "/images/home-hero-img-en.jpg"
            }
            height={525}
            width={574}
            alt="a man who brings books"
          />
          <p className={`${locale === "ar" ? "cairo" : "quicksand"} text-[#3BA9FF] font-bold text-2xl leading-relaxed font-cairo max-[1300px]:text-center max-[800px]:text-start max-[400px]:text-xl`}>
            {tHeroSection("subImg")}
          </p>
        </article>
      </section>
    </section>
  );
}

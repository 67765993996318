import React from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function Button({
  link,
  title,
  className,
  icon,
  iconHeight,
  iconWidth,
  altIcon,
  onClick,
  whatsapp
}) {
  const newClassName = twMerge(
    `flex outline-none cursor-pointer focus:outline-none whitespace-nowrap items-center  justify-center rounded-2xl w-fit px-[20px] py-[11.5px] text-lg ${
      icon ? "gap-4" : ""
    } bg-[#2775FF] text-white font-normal hover:text-[#2775FF] hover:bg-white ease-linear duration-75 border border-[#2775FF]`,
    className
  );

  const ButtonElement = link ? "a" : "button";

  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if(whatsapp){
      return window.open(link, '_blank');
    }

    if (link) {
      navigate(link);
    }
  };

  return (
    <ButtonElement className={newClassName} onClick={handleClick}>
      {icon ? (
        <img
          src={icon}
          height={iconHeight}
          width={iconWidth}
          alt={altIcon || ""}
        />
      ) : null}
      {title}
    </ButtonElement>
  );
}

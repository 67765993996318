"use client";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "./Card.tsx";
import React from "react";
import { useLocale } from "../../../store/LocaleContext.js";

export default function SwiperSpecializations({
  specializations,
}: {
  specializations: {
    id: number;
    title: string;
    image: string;
    imageAlt: string;
    tags: {
      id: number;
      title: string;
    }[];
    linkName: string;
    linkHref: string;
  }[];
}) {
  const locale = useLocale();

  const isRTL = locale === "en" ? false : true;

  return (
    <Swiper
      className="w-full"
      loop={true}
      breakpoints={{
        700: {
          slidesPerView: 4,
        },
        500: {
          slidesPerView: 3,
        },
        450: {
          // width: 576,
          slidesPerView: 2,
        },
      }}
      spaceBetween={2}
      dir={locale === "ar" ? "rtl" : "ltr"} 
    >
      {specializations?.map((card) => (
        <SwiperSlide
          key={card?.id}
          className="py-10 min-w-[400px] max-[800px]:min-w-[300px]"
        >
          <Card card={card} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

'use client'
import { Dialog, Transition } from '@headlessui/react'
import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react'
import { useIntl } from "react-intl"

export default function Popup ( { isOpen, setIsOpen, children, buttons, content }: { isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, children: ReactNode, buttons?: ReactNode, content?: string } )
{
    function closeModal ()
    {
        setIsOpen( false )
    }


const intl = useIntl();
  const { locale } = intl;


    return (

        <Fragment>
            { children }
            <Transition appear show={ isOpen } as={ Fragment }>
                <Dialog as="div" className="relative z-10" onClose={ closeModal }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className={`${locale === 'ar' ? 'cairo': 'quicksand'} flex min-h-full items-center justify-center p-4 text-center`}>
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6   shadow-xl transition-all">

                                    <div className="mt-2">
                                        <p className="text-lg font-semibold text-[#101828] text-start" >
                                            { content }
                                        </p>
                                    </div>
                                    { buttons }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>

    )
}

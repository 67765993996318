import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";

import { AppContext } from "../../../app/[locale]/providers.tsx";
import Country from "./Country.tsx";
import { fetchSpecializedCountries } from "../../../features/accredited-universities/api/fetchSpecializedCountries.ts";

export default function Countries() {
  const intl = useIntl();
  const title =
    intl.messages.common.yourChoiceIsAvailableInTheFollowingCountries;

  const [country, setCountry] = useState(null)

  const { specific_specialization_id, specialization_id, degree_id, search, specSearch, degreeSearch, subSpec } =
    useContext(AppContext);

  const { locale } = intl;

  let { data: countries, isLoading } = useQuery({
    queryKey: [
      `countries${specific_specialization_id?.length > 0
        ? specific_specialization_id?.toString()
        : ""
      }${specialization_id?.length > 0 ? specialization_id?.toString() : ""}${degree_id?.length > 0 ? degree_id?.toString() : ""
      }`, locale, specSearch, degreeSearch, subSpec
    ],
    queryFn: () =>
      fetchSpecializedCountries(
        locale,
        `${specific_specialization_id?.length > 0
          ? `${"&subspecialization_id=" +
          specific_specialization_id?.toString()
          }`
          : ""
        }${specialization_id?.length > 0
          ? `${"&specialization_id=" + specialization_id?.toString()}`
          : ""
        }${degree_id?.length > 0
          ? `${"&degree_id=" + degree_id?.toString()}`
          : ""
        }`
      ),
  });

  useEffect(() => {
    console.log(countries)
    if (countries && countries.error <= 0) {
      let data = { ...countries };

      const pattern = new RegExp(search, "i");

      console.log(countries.country.filter(word => {
        // Case-insensitive search for the pattern in any part of the string
        return pattern.test(word.country_name);
      }))

      data.country = countries.country.filter(word => {
        // Case-insensitive search for the pattern in any part of the string
        return pattern.test(word.country_name);
      })

      setCountry(data)
    }
    if (countries && countries.error && countries.error == 1) {
      let data = { ...countries };
      data.country = []
      setCountry(data)
    }
  }, [countries, search])



  console.log(country)

  return (
    <section className="w-full">
      <h2 className="text-xl font-medium text-[#313C45] mb-9">{title}:</h2>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <div className="w-[40px] h-[40px] rounded-full border-4 border-r-[#2775FF] border-b-[#2775FF] border-t-transparent border-l-transparent animate-spin"></div>
        </div>
      ) : (
        <section className="flex flex-col gap-5 items-center max-[400px]:flex-col sm:flex-row">
          {country &&
            country.country?.map((country) => (
              <Country
                firstBtn={intl.messages.modal.firstBtnText}
                secondBtn={intl.messages.modal.secondBtnText}
                content={intl.messages.modal.contentSpec}
                tList={intl.messages.common.listUniversities}
                key={country.id}
                country={country}
              />
            ))}
          {country && country.country.length === 0 && <p className="text-center">{intl.messages.common.nocountriesfound}</p>}
        </section>
      )}
    </section>
  );
}

// LocaleContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const LocaleContext = createContext();

export function LocaleProvider({ children }) {
  const [locale, setLocaleState] = useState(() => {
    // Check if language preference exists in localStorage
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage || 'ar'; // Default locale is Arabic if not set
  });

  const setLocale = (newLocale) => {
    // Save language preference to localStorage
    localStorage.setItem('language', newLocale);
    setLocaleState(newLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  return useContext(LocaleContext);
}

import React from "react";
import University from "./University.tsx";
import { useIntl } from "react-intl";

export default function UniversityWrapper({
  university,
}: {
  university: universityType;
}) {
  const { messages, locale } = useIntl();
  const t = messages.common;
  const tModal = messages.modal;
  return (
    <University
      content={tModal.content}
      firstBtn={tModal.firstBtnText}
      secondBtn={tModal.secondBtnText}
      universityProps={university}
      websiteUrl={t.website}
      specialties={t.specialties}
    />
  );
}

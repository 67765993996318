import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../shared/components/Button.tsx';
import SwiperUniversities from './SwiperUniversities.tsx';
import { fetchUniversities } from '../../accredited-universities/api/fetchUniversities.ts';


export default function SomeAccreditedUniversities() {
    const intl = useIntl();
    const { messages, locale } = intl;

    const t = messages.home.someAccreditedUniversities;
    const tUniversities = messages.home.someAccreditedUniversities.universities;


    const UNIVERSITIES_CARDS = [
        {
            id: 1,
            title: tUniversities.firstUniversity.title,
            image: "/images/university-1.jpg",
            imageAlt: tUniversities.firstUniversity.imageAlt,
            tags: [
                { id: 1, title: tUniversities.firstUniversity.tags.firstTag },
                { id: 2, title: tUniversities.firstUniversity.tags.secondTag },
                { id: 3, title: tUniversities.firstUniversity.tags.thirdTag }
            ],
            linkName: tUniversities.firstUniversity.linkName,
            linkHref: `accredited-universities`
        },
        {
            id: 2,
            title: tUniversities.secondUniversity.title,
            image: "/images/university-1.jpg",
            imageAlt: tUniversities.secondUniversity.imageAlt,
            tags: [
                { id: 1, title: tUniversities.secondUniversity.tags.firstTag },
                { id: 2, title: tUniversities.secondUniversity.tags.secondTag },
                { id: 3, title: tUniversities.secondUniversity.tags.thirdTag }
            ],
            linkName: tUniversities.secondUniversity.linkName,
            linkHref: `accredited-universities`
        },
        {
            id: 3,
            title: tUniversities.thirdUniversity.title,
            image: "/images/university-1.jpg",
            imageAlt: tUniversities.thirdUniversity.imageAlt,
            tags: [
                { id: 1, title: tUniversities.thirdUniversity.tags.firstTag },
                { id: 2, title: tUniversities.thirdUniversity.tags.secondTag },
                { id: 3, title: tUniversities.thirdUniversity.tags.thirdTag }
            ],
            linkName: tUniversities.thirdUniversity.linkName,
            linkHref: `accredited-universities`
        },
        {
            id: 4,
            title: tUniversities.fourthUniversity.title,
            image: "/images/university-1.jpg",
            imageAlt: tUniversities.fourthUniversity.imageAlt,
            tags: [
                { id: 1, title: tUniversities.fourthUniversity.tags.firstTag },
                { id: 2, title: tUniversities.fourthUniversity.tags.secondTag },
                { id: 3, title: tUniversities.fourthUniversity.tags.thirdTag }
            ],
            linkName: tUniversities.fourthUniversity.linkName,
            linkHref: `accredited-universities`
        },
        {
            id: 5,
            title: tUniversities.fifthUniversity.title,
            image: "/images/university-1.jpg",
            imageAlt: tUniversities.fifthUniversity.imageAlt,
            tags: [
                { id: 1, title: tUniversities.fifthUniversity.tags.firstTag },
                { id: 2, title: tUniversities.fifthUniversity.tags.secondTag },
                { id: 3, title: tUniversities.fifthUniversity.tags.thirdTag }
            ],
            linkName: tUniversities.fifthUniversity.linkName,
            linkHref: `accredited-universities`
        }
    ];

    const [cards, setCards] = useState(UNIVERSITIES_CARDS)


    useEffect(() => {
        fetchUniversities(locale).then((data) => {
            if (data.error === "0") {
                let newData = data.universities;

                console.log(newData,tUniversities.fifthUniversity.linkName)

                newData = newData.map((items) => {
                    let temp = {
                        title: items.university_name,
                        image: items.university_image,
                        imageAlt: tUniversities.fifthUniversity.imageAlt,
                        tags: items.degrees.map((item,index)=>({id : index, title : item})),
                        linkName: tUniversities.fifthUniversity.linkName,
                        linkHref: `accredited-universities`
                    };

                    return temp;

                })

                setCards(newData)

            }

        })


    }, [locale])

    return (
        <section className='w-full'>
            <h2 className='text-center text-[#2E3A59] text-[2.5rem] font-semibold max-[805px]:text-3xl'>{t.title}</h2>
            <section className='w-full'>
                <SwiperUniversities universities={cards} />
            </section>
            <div className='flex items-center justify-center'>
                <Button title={t.btnText} className='w-fit px-[20px] py-[11.5px] text-base font-bold' link='/accredited-universities' />
            </div>
        </section>
    );
}

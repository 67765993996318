import React from "react";
import Divider from "../shared/components/Divider.tsx";
import { handleSendMessage } from "../utils/ForwordToWhatsapp.ts";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

export default function Footer() {
  const intl = useIntl();

  let messages = intl.messages;

  const t = messages.footer;
  const locale = intl.locale;

  const tLinksCompany = {
    title: t.company.title,
    links: [
      { id: 1, title: t.company.subLinks.firstLink, href: "/about" },
      { id: 2, title: t.company.subLinks.secondLink, href: "/services" },
     
      { id: 3, title: t.company.subLinks.fourthLink, href: "/contact-us" },
    ],
  };

  const tQuickLinks = {
    title: t.quickLinks.title,
    links: [
      { id: 1, title: t.quickLinks.subLinks.firstLink, href: "/" },
      { id: 2, title: t.quickLinks.subLinks.secondLink, href: "/accredited-universities" },
      { id: 3, title: t.quickLinks.subLinks.thirdLink, href: "/accredited-specializations" },
      { id: 4, title: t.quickLinks.subLinks.fourthLink, href: "/how-to-apply" },
    ],
  };

  console.log(tQuickLinks);

  const tContactUs = t.contactUs;
  const copyright = t.subFooter.copyright;

  return (
    <footer className={`${locale === "ar" ? "cairo" : "quicksand"} w-full pt-10 bg-[#FBFBFD]`}>
      <section className="w-full max-w-[1700px] max-[1800px]:px-20 max-[1100px]:px-10 mx-auto">
        <section className="flex gap-6 my-8 flex-row footer-align-view max-[800px]:gap-10">
          <article className="flex flex-col gap-3 basis-1/2 footer-logo-para">
            <img
              src="/icons/logo.png"
              alt="tasheel logo"
              className="w-[20vh]"
            />
            <div className="flex flex-col gap-2">
              <p className="text-xl font-medium text-[#0D1216] max-[400px]:text-lg max-[370px]:text-base">
                {t.description.firstLine}
              </p>
              <p className="text-xl font-medium text-[#0D1216] max-[400px]:text-lg max-[370px]:text-base">
                {t.description.secondLine}
              </p>
              <p className="text-xl font-medium text-[#0D1216] gap-2 max-[400px]:text-lg max-[370px]:text-base max-[344px]:items-start">
                {t.description.thirdLine}<span className="font-bold px-2">{t.description.finalWords}</span>
              </p>
            </div>
          </article>
          <article className="flex mt-5 gap-28 justify-evenly basis-1/2 footer-links">
            <div className={`flex flex-col gap-6 max-[768px]:order-1 ${ locale === "ar" ? "order-3" : "order-1" }`}>
              <span className="font-bold text-base text-[#0D1216]">
                {tQuickLinks?.title}
              </span>
              <ul className="flex flex-col gap-6">
                {tQuickLinks?.links?.map((link) => (
                  <Link
                    className="text-[#48555F] font-medium"
                    key={link?.id}
                    to={link?.href}
                  >
                    {link?.title}
                  </Link>
                ))}
              </ul>
            </div>
            <div className={`flex flex-col gap-6 max-[768px]:order-2 ${ locale === "ar" ? "order-2" : "order-2" }`}>
              <span className="font-bold text-base text-[#0D1216]">
                {tLinksCompany?.title}
              </span>
              <ul className="flex flex-col gap-6">
                {tLinksCompany?.links?.map((link) => (
                  <Link
                    className="text-[#48555F] font-medium"
                    key={link?.id}
                    to={link?.href}
                  >
                    {link?.title}
                  </Link>
                ))}
              </ul>
            </div>
            <div className={`flex flex-col gap-6 max-[768px]:order-3 ${ locale === "ar" ? "order-1" : "order-3" }`}>
              <span className="font-bold text-base text-[#0D1216]">
                {tContactUs}
              </span>
              <div className="flex items-center gap-2">
                <a
                  dir="ltr"
                  href="tel:+966 920025229"
                  className="mt-1 text-base text-[#48555F] whitespace-nowrap cursor-pointer font-medium"
                >
                  +966 920025229
                </a>
                <Link target="_blank" to={handleSendMessage()}>
                  <img
                    src="/icons/global_whatsapp_btn.svg"
                    height={23}
                    width={23}
                    alt="whatsapp icon"
                  />
                </Link>
              </div>
            </div>
          </article>
        </section>
        <Divider />
        <section className="flex justify-center items-center py-10">
          <p className="flex items-center gap-1"  dir="ltr">
            <span className="font-semibold">© Copyright {new Date().getFullYear()}, </span>
            <span className="font-bold">{copyright}</span>{" "}
          </p>
        </section>
      </section>
    </footer>
  );
}

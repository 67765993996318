import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useIntl } from "react-intl";


export default function NavLinks({ tNavLinks, className, onClick = () => "" }) {
  const newClassName = twMerge('flex items-center justify-around gap-3 w-full', className);
  const intl = useIntl();
  const { locale } = intl;

  const location = useLocation()

  const navLinks = [
    {
      id: 1,
      name: tNavLinks["accreditedUniversities"],
      href: "/accredited-universities",
      isActive: location.pathname.includes("/accredited-universities"),
    },
    {
      id: 2,
      name: tNavLinks["accreditedSpecializations"],
      href: "/accredited-specializations",
      isActive: location.pathname.includes("/accredited-specializations"),
    },
    {
      id: 3,
      name: tNavLinks["ourServices"],
      href: "/services",
      isActive: location.pathname.includes("/services"),
    },
    {
      id: 4,
      name: tNavLinks["aboutUs"],
      href: "/about",
      isActive: location.pathname.includes("/about"),
    },
    {
      id: 5,
      name: tNavLinks["contactUs"],
      href: "/contact-us",
      isActive: location.pathname.includes("/contact-us"),
    }
  ];

  return (
    <section className={newClassName}>
      {navLinks.map(link => (
        <Link
          key={link.id}
          to={link.href}
          onClick={onClick}
          className={`md:text-md sm:text-md ${locale === "ar" ? "cairo" : "quicksand"} whitespace-nowrap ${link.id === 5 && "max-[1070px]:hidden"} ${link.isActive ? "text-[#2775FF] font-semibold" : "text-[#4D4C5A] font-medium"}`}
        >
          {link.name}
        </Link>
      ))}
    </section>
  );
}

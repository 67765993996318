import React, { useState } from 'react';
import Popup from '../../../shared/components/Popup.tsx';
import { PopupButtons } from '../../../features/accredited-universities/components/University.tsx';


export default function Country({ country, tList, firstBtn, secondBtn, content }: {
    country: {
        id: string,
        country_name: string,
        country_image: string,
        country_short_image: string
    },
    tList: string,
    firstBtn: string,
    secondBtn: string,
    content?: string
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            {isOpen && (
                <Popup isOpen={isOpen} content={content} setIsOpen={setIsOpen} buttons={<PopupButtons onClick={() => setIsOpen(false)} firstBtn={firstBtn} secondBtn={secondBtn} icon={"/icons/whatsapp.svg"} iconHeight={32} iconWidth={32} altIcon='whatsapp icon' />} />
            )}
            <div className='border border-[#D0D5DD] rounded-xl py-4 px-4 w-[195px] h-[195px] max-[500px]:w-full max-[500px]:h-full max-[500px]:max-w-[200px] flex flex-col sm:w-full items-center justify-center gap-3'>
                <img src={country?.country_image} height={69} width={110} alt='icon flag' className='max-[500px]:w-[70%] max-w-[120px] max-h-[80px] rounded-lg' />
                <span className='font-semibold'>{country?.country_name}</span>
                <span onClick={() => setIsOpen(true)} className='bg-[#0097B8] font-semibold cursor-pointer text-white py-1 px-2 rounded-xl'>{tList}</span>
            </div>
        </div>
    );
}

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "../../../shared/components/Button.tsx";
import { useIntl } from "react-intl";
import { ToastContainer, toast } from 'react-toastify';

import { handleSendMessage } from "../../../utils/ForwordToWhatsapp.ts";
import { Link } from "react-router-dom";

export default function ContactUsPage() {
  const { messages, locale } = useIntl();
  const t = messages.contactUs;

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required(t.fields.firstField.required),
      last_name: Yup.string().required(t.fields.secondField.required),
      email: Yup.string().email(t.fields.thirdField.invalidEmail).required(t.fields.thirdField.required),
      phone: Yup.string().required(t.fields.fourthField.required),
      message: Yup.string().required(t.fields.fifthField.required),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // You can replace this URL with your actual endpoint

        const requestObj = {
            firstname : values.first_name,
            lastname : values.last_name,
            email : values.email,
            mobile : values.phone,
            message : values.message
        }

        const response = await axios.post(
          "https://api.tasheel.sa/login/contactus",
          requestObj,
          {
            headers: {
              "x-api-key": "1234",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Form submitted successfully:", response.data);
        toast.success(t.successMessage)
        resetForm();
        // You can add any success message or redirection logic here
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle error, display error message, etc.
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <main className={`${locale === "ar" ? "cairo" : "quicksand"} max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex gap-10 mt-10 max-[410px]:px-5 max-[370px]:px-3 mb-10 flex-col`}>
      <section className="w-full flex items-center">
        <article className="basis-[50%] w-full max-[900px]:basis-full ">
          <section className="w-full max-w-[600px] max-[900px]:max-w-full ">
            <h1 className={`text-[#0D1216] text-[2.5rem] font-medium ${locale === "ar" ? "max-[800px]:text-right" : "max-[800px]:text-left"} max-[700px]:text-2xl`}>
              {t.title}
            </h1>
            <h1 className={`text-[#42307D] leading-[3rem] mt-5 text-[2.5rem] font-medium ${locale === "ar" ? "max-[800px]:text-right" : "max-[800px]:text-left"} max-[700px]:text-2xl max-[420px]:w-[75%]`}>
              {t.heroTitle}
            </h1>
            <p className={`text-[#667085] font-light text-xl mt-5 ${locale === "ar" ? "max-[800px]:text-right" : "max-[800px]:text-left"}`}>
              {t.subTitle}
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full mt-14">
                <div className="flex gap-5 max-[800px]:gap-2 justify-between my-5 max-[800px]:my-4">
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="first_name"
                      className="text-[#344054] font-semibold"
                    >
                      {t.fields.firstField.label}
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      className="rounded-xl w-full font-semibold"
                      placeholder={t.fields.firstField.placeholder}
                      {...formik.getFieldProps("first_name")}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="text-red-500 font-medium">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="last_name"
                      className="text-[#344054] font-semibold"
                    >
                      {t.fields.secondField.label}
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      className="rounded-xl w-full font-semibold"
                      placeholder={t.fields.secondField.placeholder}
                      {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="text-red-500 font-medium">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full my-5">
                  <label htmlFor="email" className="text-[#344054] font-semibold">
                    {t.fields.thirdField.label}
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="rounded-xl w-full"
                    placeholder={t.fields.thirdField.placeholder}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 font-medium">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2 w-full my-5">
                  <label htmlFor="phone" className="text-[#344054] font-semibold">
                    {t.fields.fourthField.label}
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="rounded-xl w-full font-semibold"
                    placeholder={t.fields.fourthField.placeholder}
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-red-500 font-medium">{formik.errors.phone}</div>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2 w-full my-5">
                  <label
                    htmlFor="message"
                    className="text-[#344054] font-semibold"
                  >
                    {t.fields.fifthField.label}
                  </label>
                  <textarea
                    id="message"
                    className="rounded-xl min-h-[170px] font-semibold"
                    {...formik.getFieldProps("message")}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="font-medium text-red-500">{formik.errors.message}</div>
                  ) : null}
                </div>
                <div className="w-full my-5">
                  <Button
                    title={t.btnText}
                    className="w-full max-w-full font-medium"
                    type="submit"
                    disabled={formik.isSubmitting}
                  />
                </div>
              </div>
            </form>
          </section>
        </article>
        <article className="w-full basis-[50%] flex items-center justify-end max-[900px]:hidden">
          <img
            src={"/images/contact-us-hero.jpg"}
            height={760}
            width={539}
            alt="contact us image"
          />
        </article>
      </section>
      <section className="w-full flex flex-col gap-10">
        <h3 className="text-[#2775FF] font-semibold">{t.otherChoice.title}</h3>
        <div className="flex gap-3 justify-between max-[1050px]:flex-wrap">
          <div className="bg-[#ECF2FF] rounded-lg p-5 flex flex-col basis-1/3 min-[768px]:min-w-[300px] max-[768px]:min-w-[100%] gap-5 w-full">
            <div className="flex items-center gap-3">
              
              <a href={`tel:+966${t.otherChoice.firstChoice.phone}`}><img
                src={"/icons/phone-contact.svg"}
                height={48}
                width={48}
                alt="phone icon"
              />
              </a>
              <span className="text-[20px] font-semibold text-[#42307D]">{t.otherChoice.firstChoice.preTitle}</span>
            </div>
            <span className="text-[#2775FF]">
              {t.otherChoice.firstChoice.duration}
            </span>
            <a dir="ltr" href={`tel:+966${t.otherChoice.firstChoice.phone}`} className="text-[#2775FF] w-fit">
             +966 {t.otherChoice.firstChoice.phone}
            </a>
          </div>
          <div className="bg-[#ECF2FF] rounded-lg p-5 flex flex-col basis-1/3  min-[768px]:min-w-[300px] max-[768px]:min-w-[100%] gap-5">
            <div className="flex items-center gap-3">
              <img
                src={"/icons/message-contact.svg"}
                height={48}
                width={48}
                alt="message icon"
              />
              <span className="text-[20px] font-semibold text-[#42307D]">{t.otherChoice.secondChoice.preTitle}</span>
            </div>
            <span className="text-[#2775FF] font-medium">
              {t.otherChoice.secondChoice.desc}
            </span>
            <Link dir="ltr" target="_blank" to={handleSendMessage()} className="flex items-center gap-2 w-fit text-[#FFFFFF] rounded-xl py-[10px] px-[30px] hover:text-[#22B14C] bg-[#22B14C] hover:bg-white border-[#22B14C] outline-none focus:outline-none max-w-[220px]">
              <img
                src="/icons/global_whatsapp_btn.svg"
                height={23}
                width={23}
                alt="whatsapp icon"
              />
              <p>+966 920025229</p>
            </Link>
          </div>
          <div className="bg-[#ECF2FF] rounded-lg p-5 flex flex-col basis-1/3 min-[768px]:min-w-[300px] max-[768px]:min-w-[100%] gap-5">
            
            <div className="flex items-center gap-3">
              <img
                src={"/icons/email-contact.svg"}
                height={48}
                width={48}
                alt="email icon"
              />
              <span className="text-[20px] font-semibold text-[#42307D]">{t.otherChoice.thirdChoice.preTitle}</span>
            </div>
            <span className="text-[#2775FF] font-medium">
              {t.otherChoice.thirdChoice.desc}
            </span>
            <span className="text-[#2775FF] font-medium">
              {t.otherChoice.thirdChoice.email}
            </span>
          </div>
          <div className="bg-[#ECF2FF] rounded-lg p-5 flex flex-col basis-1/3 min-[768px]:min-w-[300px] max-[768px]:min-w-[100%] gap-5">
            <div className="flex items-center gap-3">
              <img
                src={"/icons/location_pin.svg"}
                height={48}
                width={48}
                alt="email icon"
              />
              <span className="text-[20px] font-semibold text-[#42307D]">{t.otherChoice.fourthChoice.preTitle}</span>
            </div>
            <span className="text-[#2775FF] font-medium">
              {t.otherChoice.fourthChoice.desc1} <br/>
              {t.otherChoice.fourthChoice.desc2} <br/>
              {t.otherChoice.fourthChoice.desc3} <br/>
              {t.otherChoice.fourthChoice.desc4} <br/>
              {t.otherChoice.fourthChoice.desc5} <br/>
              {t.otherChoice.fourthChoice.desc6} <br/>

            </span>
            
          </div>
        </div>
      </section>
    </main>
  );
}

import React from 'react'

export default function ArrowRight ( { className }: { className?: string } )
{
    return (
        <svg className={ className } width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0561523" y="0.939941" width="28" height="28" rx="12" fill="#F9FAFB" />
            <path d="M11.8898 19.9399L10.7231 18.7733L14.5565 14.9399L10.7231 11.1066L11.8898 9.93994L16.8898 14.9399L11.8898 19.9399Z" fill="#1B1C57" />
        </svg>

    )
}

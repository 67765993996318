import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import Button from '../shared/components/Button.tsx';
import CustomListBox from '../shared/components/CustomListBox.tsx';
import MobileLinksWrapper from './MobileLinksWrapper.tsx';
import NavLinks from './NavLinks.tsx';

export default function NavLinksWrapper() {
    const intl = useIntl();

    let messages = intl.messages;

    const navLinks = {
        accreditedUniversities: messages.header.navLinks.accreditedUniversities,
        accreditedSpecializations:  messages.header.navLinks.accreditedSpecializations ,
        ourServices:  messages.header.navLinks.ourServices ,
        aboutUs:  messages.header.navLinks.about ,
        contactUs:  messages.header.navLinks.contactUs ,
    };

    console.log(navLinks)

    return (
        <div className='w-full'>
            {/* Desktop */}
            <div className='flex items-center gap-10 w-full basis-[70%] max-[1070px]:hidden'>
                <NavLinks tNavLinks={navLinks} />
                <Button title={ messages.header.btnText} link='/how-to-apply' className='max-w-[200px] min-w-[140px] font-medium' />
                <CustomListBox />
            </div>
            {/* Mobile */}
            <MobileLinksWrapper navLinks={navLinks} btnText={ messages.header.btnText } contactUs={ messages.footer.contactUs} />
        </div>
    );
}

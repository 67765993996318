import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../shared/components/Button.tsx'; // Update the path accordingly
// Assuming you are using a different library for images
import Popup from '../../../shared/components/Popup.tsx'; // Update the path accordingly
import { handleSendMessage } from '../../../utils/ForwordToWhatsapp.ts'; // Update the path accordingly
import { Link } from 'react-router-dom';

 // Update the path accordingly

export default function University({ universityProps, websiteUrl, specialties, firstBtn, secondBtn, content }) {
    const intl = useIntl();
    const locale = intl.locale;
    const [isOpen, setIsOpen] = useState(false);

    const handlePopupClose = () => {
        setIsOpen(false);
    };

    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen} content={content} buttons={<PopupButtons onClick={handlePopupClose} firstBtn={firstBtn} secondBtn={secondBtn} icon={"/icons/whatsapp.svg"} iconHeight={32} iconWidth={32} altIcon='whatsapp icon' />}>
            <article className='flex items-stretch gap-4 border rounded-md p-2 basis-[32%] max-[750px]:basis-full min-w-[320px] max-[355px]:min-w-full'>
                <img src={universityProps?.university_image} height={71} width={71} alt='university image' className='h-full object-contain min-w-[80px] max-[355px]:min-w-[60px] max-[355px]:object-contain' />
                <div className='flex flex-col items-start gap-1'>
                    <h3 className='text-lg font-semibold'>{universityProps?.university_name}</h3>
                    <div className={`flex items-center gap-3 ${locale === "ar" ? "flex-row-reverse" : "flex-row"} max-[345px]:flex-col max-[345px]:items-start`}>
                        <Link to={universityProps?.university_website || ""} className='flex items-center gap-1 text-[#0097B8] whitespace-nowrap md:text-base sm:text-sm text-xs' target="_blank" rel="noopener noreferrer">
                            🌐<span className='font-medium'>{websiteUrl}</span>
                        </Link>
                        <div className='flex items-center gap-1'>
                            <img src={universityProps?.country_short_image} className='rounded-full md:h-[20px] sm:h-[16px] h-[16px]' alt='country flag' />
                            <span className='font-medium text-[#707579] md:text-base sm:text-sm text-xs'>{universityProps?.country_name}</span>
                        </div>
                    </div>
                    <span onClick={() => setIsOpen(true)} className='bg-[#0097B8] cursor-pointer text-white max-w-min px-2 py-[2px] rounded-md font-medium'>
                        {specialties}
                    </span>
                </div>
            </article>
        </Popup>
    );
}

export const PopupButtons = ({ firstBtn, secondBtn, icon, iconHeight, iconWidth, altIcon, onClick }) => {
    const intl = useIntl();
    const locale = intl.locale;
    return (
        <div className='mt-4 flex flex-col md:flex-row md:justify-center gap-4 max-[450px]:w-full justify-center items-center'>
            {
                locale === 'ar' ? 
                <>
                    <Button whatsapp={true} link={handleSendMessage()} title={firstBtn} className='font-semibold bg-[#22B14C] border border-[#22B14C] hover:text-[#22B14C] w-full modal-whp-btn' icon={icon} iconHeight={iconHeight} iconWidth={iconWidth} altIcon={altIcon} />
                    <Button onClick={onClick} title={secondBtn} className='font-semibold text-[#344054] bg-white hover:bg-slate-100 border-gray-300 hover:text-[#344054] w-full modal-not-btn' />
                </> 
            : 
                <>
                    <Button onClick={onClick} title={secondBtn} className='font-semibold text-[#344054] bg-white hover:bg-slate-100 border-gray-300 hover:text-[#344054] w-full modal-not-btn' />
                    <Button whatsapp={true} link={handleSendMessage()} title={firstBtn} className='font-semibold bg-[#22B14C] border border-[#22B14C] hover:text-[#22B14C] w-full modal-whp-btn' icon={icon} iconHeight={iconHeight} iconWidth={iconWidth} altIcon={altIcon} />
                </>
            }
            
        </div>
    );
};



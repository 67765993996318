import React from 'react';
import { useIntl } from 'react-intl';

export default function AboutPage() {
    const intl = useIntl();
    const t =  intl.messages.about
    const locale = intl.locale;

    return (
        <main className={`${locale === "ar" ? "cairo" : "quicksand"} max-w-[1700px] max-[1800px]:px-20 max-[1170px]:px-10 max-[800px]:px-8 mx-auto flex gap-5 mt-10 max-[410px]:px-5 max-[370px]:px-3 mb-10 max-[900px]:flex-col`}>
            <section className='flex flex-col justify-between gap-2 mt-2 w-[65%] basis-[65%] max-[900px]:basis-full max-[900px]:items-center max-[800px]:basis-[100%] max-[800px]:w-[100%]'>
                <div className='max-w-[600px] flex flex-col gap-3'>
                    <h1 className={`text-[#0D1216] text-[2.5rem] font-[500] ${locale === "ar" ? "max-[800px]:text-right" : "max-[800px]:text-left"} max-[700px]:text-2xl`}>{t.title}</h1>
                    <p className='font-medium text-[#313C45] text-xl  max-[700px]:text-lg'>{t.description.firstParagraph}</p>
                    <p className='font-medium text-[#313C45] text-xl  max-[700px]:text-lg'>{t.description.secondParagraph}</p>
                    <p className='font-medium text-[#313C45] text-xl  max-[700px]:text-lg'>{t.description.thirdParagraph}</p>
                    <p className='font-medium text-[#313C45] text-xl  max-[700px]:text-lg'>{t.description.fourthParagraph}</p>
                </div>
            </section>
            <img src="/images/about-hero.jpg" alt='about hero image' className='w-[35%] basis-[35%] max-[900px]:basis-full object-contain max-[700px]:hidden' />
        </main>
    );
}

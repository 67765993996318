"use client";

import React, { Suspense, useContext, useEffect, useState } from "react";

import { AppContext } from "../../../app/[locale]/providers.tsx";
import Button from "../../../shared/components/Button.tsx";
import { UNIVERSITIES } from "../constants/universities";
import UniversityWrapper from "./UniversityWrapper.tsx";
import { fetchUniversities } from "../api/fetchUniversities.ts";
import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import University from "./University.tsx";
import { useLocation } from "react-router-dom";

export default function Universities() {
  const intl = useIntl();
  const { messages, locale } = intl;
  const t = messages.common;
  const title = t.yourChoiceIsAvailableInTheFollowingUniversities;
  const btnText = t.showMore;

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [universitiesData, setUniversityData] = useState<universityType[]>([]);

  const { country_id, specialization_id, degree_id, search, specSearch, degreeSearch, subSpec } = useContext(AppContext);
  const location = useLocation()

  const {
    data: universities,
    isLoading,
  }: {
    data:
    | ArrayResponseType<{
      id: string;
      country_name: string;
      country_image: string;
      country_short_image: string;
      university_name: string;
      university_website: string;
      university_image: string;
      degrees: Array<string>;
    }>
    | undefined;
    isLoading: boolean;
  } = useQuery({
    queryKey: [
      `universities${country_id?.length > 0 ? country_id?.toString() : ""}${specialization_id?.length > 0 ? specialization_id?.toString() : ""
      }${degree_id?.length > 0 ? degree_id?.toString() : ""}&page=${page}`,
      page, search, locale, location, specSearch, degreeSearch, subSpec
    ],
    queryFn: () => {
      console.log("fetching");
      return fetchUniversities(
        locale,
        `${country_id?.length > 0
          ? `${"&country_id=" + country_id?.toString()}`
          : ""
        }${specialization_id?.length > 0
          ? `${"&specialization_id=" + specialization_id?.toString()}`
          : ""
        }${degree_id?.length > 0
          ? `${"&degree_id=" + degree_id?.toString()}`
          : ""
        }&start=${search.length > 0 ? "" : page}&length=${search.length > 0 ? "9" : "9"}&search=${search}`
      );
    },
  });



  console.log(universities);

  useEffect(() => {
    setUniversityData([])
    setPage(0)
  }, [country_id, specialization_id, locale, degree_id, search])

  useEffect(() => {

    if (universities && universities.universities) {


      let newData = [
        ...page > 0 ? universitiesData : [],
        ...(universities.universities || []),
      ]

      setUniversityData(newData);
    }
  }, [universities]);

  console.log(page);

  if (!universitiesData || !universities) {
    return null;
  }

  return (
    <section className="w-full flex flex-col gap-10 ">
      <h2 className="font-medium text-xl text-[#313C45] max-[600px]:text-lg">{title}</h2>
      {isLoading ? (
        <section className="flex items-center justify-center">
          <div className="w-[40px] h-[40px] rounded-full border-4 border-r-[#2775FF] border-b-[#2775FF] border-t-transparent border-l-transparent animate-spin"></div>
        </section>
      ) : (
        <section className="w-full">
          {universitiesData?.length >= 1 && (
            <section className="w-full flex flex-wrap gap-4 justify-flex-start">
              {universitiesData?.map((university) => (
                <UniversityWrapper
                  key={university?.id}
                  university={university}
                />
              ))}
              {universitiesData && universitiesData?.length === 0 && <p className="text-center">No University found</p>}
              {universities && universities?.universities?.length > 8 && (
                <div className="flex items-center justify-center w-full">
                  <Button
                    title={btnText}
                    className="text-[#2775FF] border-[#2775FF] rounded-[8px] border-[3px] font-bold bg-white hover:text-white hover:bg-[#2775FF]"
                    onClick={() => setPage(page + 1)}
                  />
                </div>
              )}
            </section>
          )}
        </section>
      )}
    </section>
  );
}

import { useIntl } from "react-intl";

import Divider from "../../../shared/components/Divider.tsx";

import React from "react";

export default function Cards() {
  const { messages, locale } = useIntl();
  const t = messages.howToApply;

  const cards = [
    {
      id: 1,
      title: t.tips.firstTip.title,
      description: t.tips.firstTip.description,
      description2: t.tips.firstTip.description2,
      description3: t.tips.firstTip.description3,
    },
    {
      id: 2,
      title: t.tips.secondTip.title,
      description: t.tips.secondTip.description,
    },
    {
      id: 3,
      title: t.tips.thirdTip.title,
      description: t.tips.thirdTip.description,
    },
    {
      id: 4,
      title: t.tips.fourthTip.title,
      description: t.tips.fourthTip.description,
    },
    {
      id: 5,
      title: t.tips.fifthTip.title,
      description: t.tips.fifthTip.description,
      img: "/images/how-to-apply/fifth-img.jpg",
    },
    {
      id: 6,
      title: t.tips.sixthTip.title,
      description: t.tips.sixthTip.description,
      img: "/images/how-to-apply/sixth-img.jpg",
    },
    {
      id: 7,
      title: t.tips.seventhTip.title,
      description: t.tips.seventhTip.description,
    },
    {
      id: 8,
      title: t.tips.eighthTip.title,
      description: t.tips.eighthTip.description,
    },
    {
      id: 9,
      title: t.tips.ninthTip.title,
      description: t.tips.ninthTip.description,
      img: "/images/how-to-apply/ninth-img.jpg",
    },
  ];

  return (
    <section className="flex flex-col gap-14 mt-10">
      <section className="w-full flex items-center justify-start">
        <article className="flex items-center gap-10 justify-center shadow-[10px_10px_10px_0px_#E538171C] border border-[#E53817] rounded-[32px] p-10 max-w-[871px] w-full max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 max-[430px]:flex-row">
          <div className="text-[#E53817] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full  flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[0]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#E53817] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[0]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[0]?.description}
              <span className="text-[#008ACC]">{cards[0]?.description2}</span>
              {cards[0]?.description3}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-end">
        <article className="flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#3184811C] border border-[#318481] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#318481] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[1]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#318481] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[1]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[1]?.description}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-start">
        <article className="flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#ED8F101C] border border-[#ED8F10] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#ED8F10] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[2]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#ED8F10] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[2]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[2]?.description}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-end">
        <article className="flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#ADADAD1C] border border-[#ADADAD] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#ADADAD] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[3]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#ADADAD] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[3]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[3]?.description}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-start">
        <article className={`relative flex items-center gap-5 justify-center shadow-[10px_10px_10px_0px_#008ACC1C] border border-[#008ACC] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row ${locale === "ar" ? 'max-[430px]:pl-10' : 'max-[430px]:pr-10'}`}>
          <div className="text-[#008ACC] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[4]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%] mb-10 max-[430px]:mb-5">
            <h2 className="text-[#008ACC] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[4]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[4]?.description}
            </p>
            <img
              src={locale === "ar" ? "/images/how-to-apply/fifth-img.jpg" : "/images/how-to-apply/fifth-img-en.png" }
              height={55}
              width={473}
              alt={t.tips.fifthTip.title}
              className={`absolute bottom-5  max-[960px]:scale-[0.7] max-[430px]:h-8 max-[430px]:w-auto max-[430px]:bottom-[10%] ${
                locale === "ar" ? "max-[430px]:left-[0%]" : "max-[430px]:right-[-9%]"
              }`}
            />
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-end">
        <article className="relative flex items-center gap-5 justify-center shadow-[10px_10px_10px_0px_#85232D1C] border border-[#85232D] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#85232D] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[5]?.id}
          </div>
          <img
            src={"/images/how-to-apply/sixth-img.jpg"}
            height={86}
            width={142.19}
            alt={t.tips.sixthTip.title}
            className={`absolute bottom-3  max-[960px]:scale-[0.7] max-[700px]:h-[50px] max-[700px]:w-auto  ${
              locale === "ar"
                ? "right-3 max-[700px]:-right-5  max-[430px]:right-0"
                : "left-3 max-[700px]:-left-5 max-[430px]:left-0"
            }`}
          />
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%] max-[430px]:pb-2">
            <h2 className="text-[#85232D] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[5]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[5]?.description}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-start">
        <article className="flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#DD00631C] border border-[#DD0063] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#DD0063] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[6]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#DD0063] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[6]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[6]?.description}
            </p>
          </div>
        </article>
      </section>
      <section className="w-full flex items-center justify-end">
        <article className="flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#5DB90F1C] border border-[#5DB90F] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-8 w-full max-[430px]:flex-row">
          <div className="text-[#5DB90F] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[7]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-5 basis-[85%]">
            <h2 className="text-[#5DB90F] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl max-[430px]:text-sm">
              {cards[7]?.title}
            </h2>
            <p className="text-black text-2xl font-medium max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
              {cards[7]?.description}
            </p>
          </div>
        </article>
      </section>

      <section className="w-full flex items-center justify-start">
        <article className="relative flex items-center gap-10  justify-center shadow-[10px_10px_10px_0px_#FFA82A1C] border border-[#FFA82A] rounded-[32px] p-10 max-w-[871px] max-[700px]:gap-4 max-[700px]:px-5 max-[700px]:py-4 w-full max-[430px]:flex-row">
          <div className="text-[#FFA82A] text-8xl font-medium basis-[10%] max-[700px]:basis-[5%] w-full flex items-center justify-center max-[800px]:text-7xl max-[430px]:text-4xl">
            {cards[8]?.id}
          </div>
          <Divider className="w-[4px] min-h-[189px] max-[430px]:min-h-[74px] max-[430px]:w-[1.5px] rounded-full bg-[#D9D9D9]" />
          <div className="basis-[85%] flex gap-3 justify-around items-center max-[700px]:basis-[95%] max-[650px]:justify-between max-[650px]:gap-0 max-[430px]:flex-row">
            <div className="flex flex-col gap-5 w-full ">
              <h2 className="text-[#FFA82A] text-4xl font-semibold text-center max-[800px]:text-3xl max-[550px]:text-2xl min-w-[170px] max-[430px]:text-sm">
                {cards[8]?.title}
              </h2>
              <p className="text-black text-2xl font-medium text-center max-[800px]:text-xl max-[550px]:text-lg max-[430px]:text-xs">
                {cards[8]?.description}
              </p>
            </div>
            <img
              src={cards[8]?.img || "/images/how-to-apply/ninth-img.jpg"}
              height={174}
              width={174}
              alt={t.tips.ninthTip.title}
              className="max-[800px]:scale-75 max-[550px]:max-w-[90px]"
            />
          </div>
        </article>
      </section>
    </section>
  );
}
